import {authGet, authPost, authPut} from "./authCalls";

export function cancelSubscription(subscription_id: string | undefined){
    authPut(`/v1/subscriptions/cancel-subscription`, {subscription_id}).then(()=>{window.location.reload()});
}

export function updatePaymentMethod(){
    authGet(`/v1/subscriptions/update-payment-method`).then((response)=>{window.location.replace(response.data.url)});
}

export function renewSubscription(subscription_id: string | undefined){
    authPut(`/v1/subscriptions/renew-subscription/${subscription_id}`).then(()=>{window.location.reload()});
}


