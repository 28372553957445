const _paq = window._paq = window._paq || [];

export function trackEvent(category: string, action: string, name: string, value?: number) {
    const eventArray : (number | string)[] = ['trackEvent', category, action, name]
    if(value){
        eventArray.push(value)
    }
    _paq.push(eventArray);
}

export function trackFormConversion(formName: string){
    window._paq.push(['FormAnalytics::trackFormConversion', formName]);
}
