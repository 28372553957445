import SimpleStatistic from "./charts/SimpleStatistic";
import { useState } from "react";
import { green, orange, red } from "@ant-design/colors";
import {allCustomers} from "../../pages/AnalyticsPage";
import {Tooltip} from "antd";

interface TotalCustomersNumberProps {}

const CustomersActiveNotifications = () => {

  function calculateNotificationPercentage(usersArray: any) {
    // Filter the array to include only users with notifications_allowed set to true
    const usersWithNotifications = usersArray.filter((user: any) => user.notifications_allowed);

    // Calculate the percentage of users with active notifications
    return (usersWithNotifications.length / usersArray.length) * 100;
  }

  let number: number = 0;
  let noData = false;

 if(allCustomers.value.length < 1){
    noData = true
  }else {
    number = calculateNotificationPercentage(allCustomers.value)
  }

  function giveFeedback(){
    if(number > 79)
      return {color: green[6], tooltip: 'Ottimo! la percentuale è sopra la media'}
    if(number > 35)
      return {color: orange[5], tooltip: 'La percentuale è nella media'}
    else
      return {color: red[5], tooltip: 'Da migliorare, la percentuale è sotto la media'}
  }

  return <SimpleStatistic tooltipText={giveFeedback().tooltip} noData={noData} valueStyle={{color: giveFeedback().color}} title={'Clienti con notifiche attive'} suffix={'%'} value={number}/>;
};
export default CustomersActiveNotifications;
