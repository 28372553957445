import React, {useState} from 'react';
import {Form, Input, Row, Col, Switch, Checkbox, Button, Select, Flex, FormProps, FormInstance, FormRule} from 'antd';
import axios from "axios";
import {useDebouncedCallback} from "use-debounce";
import {v4 as uuid} from "uuid";
import {AddressValueSchema} from "../types/inputTypes";

const defaultRules = [
    {
        validator: (_: any, value: any) => {
            const result = AddressValueSchema.safeParse(value)
            if (result.success) {
                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        },
    },
]


const AddressFormItem = ({
                             name = 'address',
                             label = 'Indirizzo',
                             rules = defaultRules,
                             onlyManual = false,
                             preserve = false,
                             form,
                             sessionToken = uuid()
                         }: {
    name?: (number | string) | (number | string)[],
    label?: string,
    rules?: FormRule[],
    onlyManual?: boolean,
    preserve?: boolean,
    form: FormInstance,
    sessionToken?: string
}) => {
    const nameIsArray = Array.isArray(name)
    const isManual = Form.useWatch(nameIsArray ? ['extraSalesPoints', ...name, 'manual'] : [name, 'manual'], form);

    function toggleManual() {
        form.setFieldValue(nameIsArray ? ['extraSalesPoints', ...name, 'manual'] : [name, 'manual'], !isManual)
    }

    const [options, setOptions] = useState([]);

    const fetchAddresses = async (search: string) => {

        if (!search) {
            return
        }
        const apiKey = 'AIzaSyCyn_Mij0VVEDJkI-ygvsnW1RbvV5xrGxA';
        try {
            const results = await axios.post('https://places.googleapis.com/v1/places:autocomplete', {
                input: search,
                sessionToken,
                includedRegionCodes: ['it'],
                includedPrimaryTypes: ['finance', 'food', 'route', 'street_address', 'street_number']
            }, {
                headers: {
                    "Content-Type": 'application/json',
                    'X-Goog-Api-Key': apiKey,
                }
            })
            if (!results.data.suggestions) {
                setOptions([])
            }
            const mappedResults = results.data.suggestions.map((item: any) => (
                {value: item.placePrediction.placeId, label: item.placePrediction.text.text}
            ))
            setOptions(mappedResults)
        } catch (e) {
            console.log(e)
        }
    };

    const debouncedSearch = useDebouncedCallback(async (search: string) => {
        return await fetchAddresses(search)
    }, 100)

    return (
        <Form.Item
            label={label}
            rules={rules}
            shouldUpdate={true}
        >
            <Form.Item
                name={nameIsArray ? [...name, 'manual'] : [name, 'manual']}
                initialValue={onlyManual}
                hidden
            />
            {isManual ? (
                <>
                    <Form.Item
                        preserve={preserve}
                        name={nameIsArray ? [...name, 'address'] : [name, 'address']}
                        noStyle
                        rules={[{required: true, message: ''}]}
                    >
                        <Input placeholder="Indirizzo"/>
                    </Form.Item>
                    <Form.Item
                        preserve={preserve}
                        name={nameIsArray ? [...name, 'city'] : [name, 'city']}
                        noStyle
                        rules={[{required: true, message: ''}]}
                    >
                        <Input placeholder="Città" style={{marginTop: 8}}/>
                    </Form.Item>
                    <Flex gap={6}>
                        <Form.Item
                            preserve={preserve}
                            name={nameIsArray ? [...name, 'postal_code'] : [name, 'postal_code']}
                            noStyle
                            rules={[{required: true, message: ''}]}
                        >
                            <Input placeholder="CAP" style={{marginTop: 8}}/>
                        </Form.Item>
                        <Form.Item
                            preserve={preserve}
                            name={nameIsArray ? [...name, 'state'] : [name, 'state']}
                            noStyle
                            rules={[{required: true, message: ''}]}
                        >
                            <Input placeholder="Provincia" style={{marginTop: 8}}/>
                        </Form.Item>
                    </Flex>
                </>
            ) : (
                <>
                    <Form.Item preserve={preserve} rules={[{required: true, message: 'Seleziona un indirizzo'}]}
                               name={nameIsArray ? [...name, 'selected'] : [name, 'selected']}
                               shouldUpdate={true}
                               noStyle
                    >
                        <Select
                            notFoundContent={<p style={{textAlign: 'center', color: 'grey', fontSize: 12}}>Cerca un
                                indirizzo</p>}
                            showSearch
                            labelInValue={true}
                            filterOption={false}
                            placeholder={"Via Rossi 21, Roma"}
                            options={options}
                            onSearch={fetchAddresses}
                        />
                    </Form.Item>
                    <Form.Item hidden name={nameIsArray ? [...name, 'sessionToken'] : [name, 'sessionToken']}
                               initialValue={sessionToken} preserve={preserve}/>
                </>
            )}
            {!onlyManual && <a style={{fontSize: 13, marginLeft: 10, display: "block", marginTop: 6}}
                               onClick={toggleManual}>{isManual ? 'Cerca indirizzo' : 'Non trovo il mio indirizzo'}</a>}

        </Form.Item>
    );
};

export default AddressFormItem;
