import ContainerBlock from "./ContainerBlock";
import SingleLineChartTimeFormat from "./charts/SingleLineChartTimeFormat";
import {groupedTransactions} from "../../pages/AnalyticsPage";
import {sumPoints, sumYValues} from "../../utils/analytics";

const SpentPointsTrend = () => {

    const data = sumPoints(groupedTransactions.value, 1)

  return (
    <ContainerBlock total={sumYValues(data)} title={"Punti spesi dai clienti"}>
      <SingleLineChartTimeFormat data={data}/>
    </ContainerBlock>
  );
};
export default SpentPointsTrend;
