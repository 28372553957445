import { Button, theme } from "antd";
import { supabase } from "../utils/supabase";
import { logged, onBoarded, userId, verified } from "../App";
import {COLORS} from "../utils/colors";

const OnBoardLayout = ({ children, logOutBtn = false }: any) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  async function signOut() {
    const { error } = await supabase.auth.signOut();
    userId.value = "";
    logged.value = false;
    verified.value = false;
    onBoarded.value = false;
  }


  return (
    <div
      style={{
        minHeight: "calc(100vh - 120px)",
        padding: 20,
        margin: "auto",
        paddingTop: 100,
          backgroundColor: COLORS.businessColor,
      }}
    >
      <img
        alt={"next is free logo"}
        src={"https://app.nextisfree.io/logo-white.png"}
        width={100}
        style={{ position: "absolute", left: 15, top: 15 }}
      />
      {logOutBtn && (
        <Button
          onClick={signOut}
          type={"text"}
          style={{ position: "absolute", top: 20, right: 20 }}
        >
          Esci
        </Button>
      )}

      {children}
    </div>
  );
};
export default OnBoardLayout;
