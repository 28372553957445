import {Button, Form, Input, Layout, message} from "antd";
import React, {useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import {logged} from "../App";
import LoginLayout from "../layouts/LoginLayout";
import {Link} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {blue, green} from "@ant-design/colors";
import {trackEvent, trackFormConversion} from "../utils/Matomo/tracking_functions";

const LoginPage = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [resetPasswordSent, setResetPasswordSent] = useState(false);

    const [form] = Form.useForm();

    async function resetPassword() {
        const email = form.getFieldValue("email");
        const {data, error} = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://app.nextisfree.io/update-password/',
        })
        if (error) {
            message.error(error.message);
            return
        }
        setResetPasswordSent(true)
        trackEvent('Password Recovery', 'Reset email sent', email)
    }

    async function onFinish(values) {
        setLoading(true);
        const {data, error} = await supabase.auth.signInWithPassword({
            email: values.email,
            password: values.password,
        });
        if (error) {
            logged.value = false;
            messageApi.error('Email o password non corretti');
            setLoading(false);
            return;
        }
        if (data) {
            if (data.user.user_metadata.shop !== true) {
                await supabase.auth.updateUser({data: {shop: true}})
            }
            trackFormConversion('login')
            window.location.reload();
        }
    }


    return (
        <LoginLayout>
            <div data-matomo-form={true} data-matomo-name="login">
                <h1 style={{marginBottom: 30}}>Accedi</h1>
                <Form
                    name={'LoginForm'}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {contextHolder}
                    <Form.Item
                        required={false}
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Email non valida",
                            },
                        ]}
                    >
                        <Input placeholder={"Email"}/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        required={false}
                        rules={[
                            {
                                required: true,
                                message: "Password non valida",
                            },
                        ]}
                    >
                        <Input.Password placeholder={"Password"}/>
                    </Form.Item>

                    <div style={{textAlign: "center"}}>
                        <Form.Item>
                            <Button
                                loading={loading}
                                disabled={loading}
                                style={{width: "100%", marginTop: 10}}
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                Accedi
                            </Button>
                        </Form.Item>
                        <AnimatePresence>
                            {resetPasswordSent ? (
                                <motion.div style={{lineHeight: 1.2, color: blue[4]}} initial={{opacity: 0, y: 10}}
                                            animate={{opacity: 1, y: 0}} onClick={resetPassword}>
                                    Se l'email inserita appartiene ad un account, riceverai un'email per il recupero
                                    della password
                                </motion.div>
                            ) : (
                                <motion.a onClick={resetPassword}>
                                    Ho dimenticato la password
                                </motion.a>
                            )}
                        </AnimatePresence>
                    </div>
                </Form>
                <p
                    style={{
                        fontSize: 14,
                        textAlign: "center",
                        width: "100%",
                        margin: "10px 0",
                        marginTop: 40,
                    }}
                >
                    Non hai un account?
                </p>
                <Link to={"/sign-up"}>
                    <Button
                        size={"large"}
                        style={{width: "100%"}}
                    >
                        Registrati
                    </Button>
                </Link>
            </div>
        </LoginLayout>
    );
};
export default LoginPage;
