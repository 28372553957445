import {AnimatePresence, motion} from "framer-motion";
import {blue, orange, red} from "@ant-design/colors";
import {Button} from "antd";
import React from "react";
import {COLORS} from "../utils/colors";
import {trackEvent} from "../utils/Matomo/tracking_functions";

interface TopInfoBannerProps {
    boldText: string,
    text: string,
    buttonText: string,
    onClick: ()=>void,
    buttonLoading?: boolean
}

const TopInfoBanner = ({
  boldText,
  text,
  buttonText,
  onClick,
  buttonLoading = false,
}: TopInfoBannerProps) => {

    function onClickWithTracking(){
        trackEvent('Top Info banner', 'Button Click', text);
        onClick()
    }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={'topInfoBanner-w'}
        style={{
          width: "100%",
          backgroundColor: COLORS.primaryLight,
          display: "flex",
          padding: 20,
          justifyContent: "",
          alignItems: "center",
            gap: 10,
            borderBottom: `1px solid ${COLORS.primary}`,
        }}
      >
        <div>
          <p style={{ color: 'black' }}>
            <b>{boldText}</b>
            {text}
          </p>
        </div>
        <Button
          loading={buttonLoading}
          size={'small'}
          onClick={onClickWithTracking}
          type={"primary"}
        >
          {buttonText}
        </Button>
      </motion.div>
    </AnimatePresence>
  );
};
export default TopInfoBanner;
