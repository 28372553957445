import {Button, Flex, Form, message, Skeleton} from "antd";
import {authPost} from "../utils/authCalls";
import {subscription} from "../App";
import { green, red} from "@ant-design/colors";
import {useState} from "react";
import SalesPointsForm from "../components/billings/SalesPointsForm";
import {
    updatePaymentMethod,
} from "../utils/stripe";
import Subscription from "../components/billings/Subscription";
import SubscriptionInput from "../components/SubscriptionInput";
import {useForm} from "antd/es/form/Form";
import Wrapper from "../components/Wrapper";


const BillingsPage = () => {

    const [subscribing, setSubscribing] = useState(false);

    const [form] = useForm()

    if (subscription.value.status === 'loading')
        return (
            <div style={{position: "absolute", top: "50%", left: "50%"}}>
                <img style={{width: 50, height: 50}} src={"./loader.svg"}/>
            </div>
        );

    const i18n: any = {
        year: "Anno + iva",
        month: "Mese + iva",
    };

    async function subscribe(values: any) {
        setSubscribing(true)
        try {
            await authPost('/v1/subscriptions/', {subscriptionType: values.subscription.subscriptionType});
            window.location.reload()
        } catch (e) {
            message.error('Qualcosa è andato storto')
        } finally {
            setSubscribing(false)
        }
    }


    return (
        <div className="container">
            <h1 style={{margin: "40px 15px"}}>{subscription.value.status === "no subscription" ? 'Non hai un abbonamento attivo' : 'Abbonamento'}</h1>
            {subscription.value.status === "no subscription" ? (
                <Form form={form} onFinish={subscribe}
                      initialValues={{subscription: {promoCode: '', subscriptionType: 'yearly'}}}
                >
                    <Form.Item name={'subscription'}>
                        {/*@ts-ignore*/}
                        <SubscriptionInput withoutPromoCode withoutFreeTrial/>
                    </Form.Item>
                </Form>
            ) : (
                <>
                    <Subscription {...subscription.value}/>
                    <>
                        {!subscription.value.default_payment_method ? (
                            <Wrapper
                                mt={10}
                                style={{
                                    backgroundColor: red[0],
                                    borderRadius: 10,
                                    marginTop: 10,
                                    borderColor: red[1],
                                    border: "1px solid",
                                    color: red[5],
                                }}
                            >
                                <h3 style={{marginBottom: 5}}>
                                    Nessun metodo di pagamento inserito
                                </h3>
                                <p>
                                    Se non inserisci un metodo di pagamento l'abbonamento sarà
                                    disattivato al termine della prova gratuita e il tuo negozio non
                                    sarà più disponibile.
                                </p>
                                <div style={{marginTop: 20, textAlign: "right"}}>
                                    <Button onClick={updatePaymentMethod} type={"primary"} danger>
                                        Aggiungi metodo di pagamento
                                    </Button>
                                </div>
                            </Wrapper>
                        ) : (
                            <Wrapper
                               mt={10}
                            >
                                <h4>Metodo di pagamento</h4>
                                <Flex justify={"space-between"} align={"center"}>
                                    <div>
                                        <Flex
                                            style={{
                                                padding: 6,
                                                backgroundColor: green[1],
                                                borderRadius: 4,
                                                marginTop: 10,
                                            }}
                                            gap={10}
                                        >
                                            {/*<PaymentIcon
                                                type={subscription.value.cardBrand}
                                                    format="flatRounded"
                                                width={30}
                                                 />*/}
                                            <p style={{fontWeight: 100, color: green[6]}}>
                                                ✱✱✱✱ ✱✱✱✱ ✱✱✱✱{" "}
                                                <span style={{fontWeight: 600}}>
                                            {subscription.value.cardLast4}
                                                 </span>
                                            </p>
                                        </Flex>
                                    </div>
                                    <Button type={'primary'} onClick={updatePaymentMethod}>Aggiorna</Button>
                                </Flex>
                            </Wrapper>
                        )}
                    </>
                </>
            )}
            <SalesPointsForm/>
            {subscription.value.status === 'no subscription' &&
                <Button size={'large'} loading={subscribing} onClick={() => form.submit()} type={'primary'}
                        style={{borderRadius: 30, width: '100%', marginTop: 20}}>Abbonati</Button>
            }
        </div>
    );
};
export default BillingsPage;
