import {supabase} from "./supabase";
import axios, {AxiosResponse} from "axios";
import {apiUrl} from "./urls";
import {message} from "antd";
import * as Sentry from "@sentry/react";

class Api {
    private baseUrl: string = process.env.REACT_APP_CLOUDFLARE_API || '';

    constructor(version: number) {
        this.baseUrl = this.baseUrl + `/v${version}`
    }

    private async getAccessToken(): Promise<string> {
        const {data, error} = await supabase.auth.getSession();
        if (error) throw error;

        const token = data?.session?.access_token;
        if (token) return token;

        await supabase.auth.signOut();
        throw new Error("Access token not found. Signed out.");
    }

    private handleError(e: any): void {
        const errorMessage = e.response?.data?.type !== 'generic'
            ? e.response?.data?.errors?.[0]
            : 'Qualcosa è andato storto';

        message.error(errorMessage);
        Sentry.captureException(e);
    }

    private async request(method: 'post' | 'put' | 'delete' | 'get', url: string, body?: any): Promise<any> {
        try {
            const accessToken = await this.getAccessToken();
            const headers = {Authorization: accessToken};
            const response = await axios({method, url: `${this.baseUrl}${url}`, data: body, headers});
            return response.data;
        } catch (e) {
            this.handleError(e);
            throw e;
        }
    }

    public post<T>(url: string, body: any): Promise<T> {
        return this.request('post', url, body);
    }

    public put<T>(url: string, body: any): Promise<T> {
        return this.request('put', url, body);
    }

    public delete<T>(url: string): Promise<T> {
        return this.request('delete', url);
    }

    public get<T>(url: string): Promise<T> {
        return this.request('get', url);
    }
}

export const apiV1 = new Api(1)
