import ContainerBlock from "./ContainerBlock";
import SingleLineChartTimeFormat from "./charts/SingleLineChartTimeFormat";
import {groupedTransactions, timeSpan} from "../../pages/AnalyticsPage";
import {sumPoints, sumYValues} from "../../utils/analytics";

const AllocatedPointsTrend = () => {

    const summedPoints = sumPoints(groupedTransactions.value, 0, timeSpan.value);

  return (
    <ContainerBlock total={sumYValues(summedPoints)} title={"Punti assegnati a clienti"}>
      <SingleLineChartTimeFormat data={summedPoints}/>
    </ContainerBlock>
  );
};
export default AllocatedPointsTrend;
