import {Button, message, Space} from "antd";
import { red } from "@ant-design/colors";
import { supabase } from "../../utils/supabase";
import { logged, onBoarded, userId, verified } from "../../App";
import ConfirmModal from "../ConfirmModal";
import { useState } from "react";
import {authDelete} from "../../utils/authCalls";
import {signOut} from "../../utils/signOut";

interface DangerZoneProps {}

const DangerZone = () => {

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function deleteShop() {
    try{
      await authDelete('/v1/shops/');
      message.success("Profilo eliminato con successo");
      window.location.reload()
    }catch (e) {

    }
  }

  return (
    <Space
      size={"middle"}
      direction={"vertical"}
      style={{
        border: "1px solid",
        borderColor: red[2],
        borderRadius: 20,
        padding: 20,
        width: "100%",
        marginTop: 70,
      }}
    >
      <Button
        onClick={signOut}
        type={"primary"}
        size={"large"}
        style={{ width: "100%" }}
        danger
      >
        Log out
      </Button>
      <Button
        onClick={() => setDeleteConfirm(true)}
        size={"large"}
        style={{ width: "100%", backgroundColor: "white" }}
        danger
      >
        Elimina profilo
      </Button>

      <ConfirmModal
        open={deleteConfirm}
        loading={confirmLoading}
        setOpen={setDeleteConfirm}
        danger={true}
        title={"Attenzione!"}
        buttonText={"Elimina definitivamente"}
        onConfirm={deleteShop}
      >
        <p>
          Se procedi tutti i dati del
          tuo account verranno eliminati <b>in modo irreversibile</b>. Gli abbonamenti
          in corso saranno annullati all'istante senza la possibilità di
          rinnovarli. Vuoi procedere?
        </p>
      </ConfirmModal>
    </Space>
  );
};
export default DangerZone;
