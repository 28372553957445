function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function isColorTooBright(red: number, green: number, blue: number, threshold: number = 0.3): boolean {
    const luminanceValue = luminance(red, green, blue);
    return luminanceValue > threshold;
}
