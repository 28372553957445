import {IconCoinEuro, IconSalad} from "@tabler/icons-react";
import {z} from "zod";
//
export const PriceListItemTypesSchema = z.enum(['section', 'item'])
export type PriceListItemTypes = z.infer<typeof PriceListItemTypesSchema>
//
export const PriceListSourceSchema = z.enum(['editor', 'link'])
export type PriceListSource = z.infer<typeof PriceListSourceSchema>
//
export const PriceListTypesSchema = z.enum(['menu', 'price-list'])
export type PriceListTypes = z.infer<typeof PriceListTypesSchema>
//
export const PriceListThemesSchema = z.enum(['modern', 'elegant', 'creative'])
export const priceListThemes: PriceListThemes[] = ['modern', 'elegant', 'creative']
export type PriceListThemes = z.infer<typeof PriceListThemesSchema>
export const priceListThemesOptions = [{
    value: 'modern',
    label: 'Moderno',
    icon: <h2 className={'modern-theme'}>A</h2>
}, {
    value: 'elegant',
    label: 'Elegante',
    icon: <h2 className={'elegant-theme'}>A</h2>
}, {
    value: 'creative',
    label: 'Creativo',
    icon: <h2 className={'creative-theme'}>A</h2>
}]
//
export const PriceListItemDataSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    price: z.number().optional(),
    allergens: z.array(z.string()).optional(),
    available: z.boolean().optional(),
    imagePath: z.string().optional(),
})
export type PriceListItemData = z.infer<typeof PriceListItemDataSchema>
//
export const priceListTypesOptions: { value: PriceListTypes, label: string, icon: React.ReactNode }[] = [
    {
        value: 'menu',
        label: 'Menù',
        icon: <IconSalad strokeWidth={1.5}/>
    },
    {
        value: 'price-list',
        label: 'Listino Prezzi',
        icon: <IconCoinEuro/>
    }
]
//
export const PriceListItemSchema = z.object({
    id: z.string().uuid(),
    type: PriceListItemTypesSchema,
    data: PriceListItemDataSchema,
})
export type PriceListItem = z.infer<typeof PriceListItemSchema>
//
export const PriceListSchema = z.object({
    type: PriceListTypesSchema,
    theme: PriceListThemesSchema,
    source: PriceListSourceSchema,
    public: z.boolean(),
    link: z.string().url().optional(),
    items: z.array(PriceListItemSchema)
})
export type PriceList = z.infer<typeof PriceListSchema>

export const PriceListDraft = PriceListSchema.and(
    z.object({
        published: z.boolean(),
    })
);

type Allergen =
    | "celery"
    | "gluten"
    | "crustaceans"
    | "eggs"
    | "fish"
    | "lupin"
    | "milk"
    | "molluscs"
    | "mustard"
    | "nuts"
    | "peanuts"
    | "sesameSeeds"
    | "soybeans"
    | "sulphurDioxide";


export const allergens: Allergen[] = [
    "celery",
    "gluten",
    "crustaceans",
    "eggs",
    "fish",
    "lupin",
    "milk",
    "molluscs",
    "mustard",
    "nuts",
    "peanuts",
    "sesameSeeds",
    "soybeans",
    "sulphurDioxide"
]


export const allergensObject: { [key in Allergen]: { label: string; icon: string } } = {
    celery: {
        label: "sedano",
        icon: "celery.svg"
    },
    gluten: {
        label: "Glutine",
        icon: "gluten.svg"
    },
    crustaceans: {
        label: "crostacei",
        icon: "crustaceans.svg"
    },
    eggs: {
        label: "uova",
        icon: "eggs.svg"
    },
    fish: {
        label: "pesce",
        icon: "fish.svg"
    },
    lupin: {
        label: "lupino",
        icon: "lupin.svg"
    },
    milk: {
        label: "latte",
        icon: "milk.svg"
    },
    molluscs: {
        label: "molluschi",
        icon: "molluscs.svg"
    },
    mustard: {
        label: "senape",
        icon: "mustard.svg"
    },
    nuts: {
        label: "noci",
        icon: "nuts.svg"
    },
    peanuts: {
        label: "arachidi",
        icon: "peanuts.svg"
    },
    sesameSeeds: {
        label: "semi di sesamo",
        icon: "sesameSeeds.svg"
    },
    soybeans: {
        label: "soia",
        icon: "soybeans.svg"
    },
    sulphurDioxide: {
        label: "anidride solforosa e solfiti",
        icon: "sulphurDioxide.svg"
    }
};
