import {Flex} from "antd";
import {blue} from "@ant-design/colors";
import {SelectOption} from "../types/inputTypes";

interface CustomRadioGroupProps {
    options: { value: any, label: string }[],
    value: any,
    onChange: (value: any) => void,
}

const CustomRadioGroup = ({options, onChange, value}: any) => {
    return (
        <Flex style={{width: '100%', borderRadius: 10, overflow: "hidden", border: `1px solid ${blue[5]}`}}>
            {options.map((item: SelectOption) => (
                <button type={'button'} key={item.value} onClick={() => {
                    onChange(item.value)
                }} style={{
                    flex: 1,
                    backgroundColor: value === item.value ? blue[0] : 'white',
                    cursor: 'pointer',
                    border: 'none',
                    padding: 15,
                    fontSize: 14,
                    fontWeight: 500,
                    color: blue[5],
                }}>
                    {item.label}
                </button>
            ))}
        </Flex>);
}
export default CustomRadioGroup;
