import {Button, Checkbox, Flex, Form, Input, message} from "antd";
import {useState} from "react";
import LoginLayout from "../layouts/LoginLayout";
import {Link, useNavigate} from "react-router-dom";
import {logged} from "../App";
import {supabase} from "../utils/supabase";
import axios from "axios";
import {authGet, authPost} from "../utils/authCalls";
import {trackFormConversion} from "../utils/Matomo/tracking_functions";

const LoginPage = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [termsConditions, setTermsConditions] = useState();

    const navigate = useNavigate();

    async function onFinish(values) {
        setLoading(true);
        const emailCheck = await authPost(`/check-email`, {email: values.email, password: values.password});
        if (emailCheck.data === 'alreadyRegistered') {
            setLoading(false)
            return messageApi.error(<p style={{display: 'inline'}}>È già presente un account associato a questo
                indirizzo email → <a href={'/login'}>Accedi</a></p>);
        } else if (emailCheck.data === 'registeredButNotShop') {
            const {data, error} = await supabase.auth.signInWithPassword({
                email: values.email,
                password: values.password,
            });
            console.log(error)
            setLoading(false)
            window.location.reload();
            return
        }

        const {data, error} = await supabase.auth.signUp({
            email: values.email,
            password: values.password,
            options: {
                emailRedirectTo: "https://app.nextisfree.io/welcome/",
                data: {
                    shop: true,
                },
            },
        });

        if (error) {
            return messageApi.error(error.message);
        }

        if (data) {
            logged.value = true;
            trackFormConversion('signUp')
            navigate("/confirm-email", {state: values.email});
        }
    }

    function handleOnFinishFailed(e) {
        const errorsToString = JSON.stringify(e.errorFields)

    }

    return (
        <LoginLayout>
            <div data-matomo-form={true} data-matomo-name="signUp" style={{width: "100%"}}>
                <h1 style={{marginBottom: 30}}>Crea un nuovo account</h1>
                <Form layout="vertical" onFinish={onFinish} onFinishFailed={handleOnFinishFailed} autoComplete="off">
                    {contextHolder}
                    <Form.Item
                        label="Email"
                        name="email"
                        required={false}
                        validateDebounce={1000}
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "Email non valida",
                            },
                        ]}
                    >
                        <Input placeholder={"Email"}/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        required={false}
                        validateDebounce={1000}
                        rules={[
                            {
                                required: true,
                                message: "Password non valida",
                            },
                            {min: 8, message: "La password deve essere minimo 8 caratteri"},
                        ]}
                    >
                        <Input.Password placeholder={"Password"}/>
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0}} name={'terms-conditions'} required valuePropName="checked"
                               rules={[
                                   {
                                       validator: (_, value) =>
                                           value ? Promise.resolve() : Promise.reject(new Error('Per continuare, è necessario spuntare questa casella.')),
                                   },
                               ]}>
                        <Flex gap={8} align={'center'}>
                            <Checkbox/><p style={{color: "gray"}}>Accetto <a target='_blank'
                                                                             href={'https://nextisfree.io/termini-condizioni'}
                                                                             style={{
                                                                                 color: 'black',
                                                                                 fontWeight: 500
                                                                             }}>termini e condizioni</a> di utilizzo
                        </p>
                        </Flex>
                    </Form.Item>
                    <Form.Item style={{marginBottom: 10}} name={'privacy'} valuePropName="checked" required rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Per continuare, è necessario spuntare questa casella.')),
                        },
                    ]}>
                        <Flex style={{marginTop: 5, marginBottom: 10}} gap={8}>
                            <Checkbox/><p style={{lineHeight: 1, color: "gray"}}>Dichiaro di aver letto <a
                            target='_blank' href={'https://nextisfree.io/privacy-policy'}
                            style={{color: 'black', fontWeight: 500}}>l'informativa privacy</a> e presto consenso
                            per tutti i dati trattati</p>
                        </Flex>
                    </Form.Item>
                    <div style={{textAlign: "center"}}>
                        <Form.Item>
                            <Button
                                loading={loading}
                                disabled={loading}
                                style={{width: "100%", marginTop: 10}}
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                Crea un account
                            </Button>
                            {/*              <p style={{ color: "#f5b141", marginTop: 5 }}>
                Non è richiesta la carta di credito.
              </p>*/}
                        </Form.Item>
                    </div>
                </Form>
                <p
                    style={{
                        fontSize: 14,
                        textAlign: "center",
                        width: "100%",
                        margin: "10px 0",
                        marginTop: 40,
                    }}
                >
                    Hai già un account?
                </p>
                <Link to={"/login"}>
                    <Button
                        size={"large"}
                        style={{width: "100%"}}
                    >
                        Accedi
                    </Button>
                </Link>
            </div>
        </LoginLayout>
    );
};
export default LoginPage;
