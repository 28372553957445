import { supabase } from "./supabase";
import axios from "axios";
import { apiUrl } from "./urls";
import { message } from "antd";
import * as Sentry from "@sentry/react";

export async function getAccessToken() {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    throw error;
  }
  if (data?.session?.access_token) {
    return data.session.access_token;
  } else {
    await supabase.auth.signOut();
  }
}

export async function authPost(url, body) {
  try {
    const accessToken = await getAccessToken();
    return await axios.post(`${apiUrl}${url}`, body, {
      headers: { Authorization: accessToken },
    });
  } catch (e) {
    if(e.response.data.type !== 'generic'){
      message.error(e.response.data.errors[0]);
    }else{
      message.error('Qualcosa è andato storto')
    }
    Sentry.captureException(e);
    throw e;
  }
}

export async function authPut(url, body) {
  try {
    const accessToken = await getAccessToken();
    return await axios.put(`${apiUrl}${url}`, body, {
      headers: { Authorization: accessToken },
    });
  } catch (e) {

    if(e.response.data.type !== 'generic'){
      message.error(e.response.data.errors[0]);
    }else{
      message.error('Qualcosa è andato storto')
    }
    Sentry.captureException(e);
    throw e;
  }
}

export async function authDelete(url, body) {
  try {
    const accessToken = await getAccessToken();
    return await axios.delete(`${apiUrl}${url}`, {
      headers: { Authorization: accessToken },
    });
  } catch (e) {

    if(e.response.data.type !== 'generic'){
      message.error(e.response.data.errors[0]);
    }else{
      message.error('Qualcosa è andato storto')
    }
    throw e;
  }
}

export async function authGet(url) {
  try {
    const accessToken = await getAccessToken();
    return await axios.get(`${apiUrl}${url}`, {
      headers: { Authorization: accessToken },
    });
  } catch (e) {
    message.error("qualcosa è andato storto");
    throw e;
  }
}
