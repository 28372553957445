import { TransactionOutlined } from "@ant-design/icons";
import {DatePicker, Flex, Select, Space, Table} from "antd";
import { useEffect, useState } from "react";
import { supabase } from "../utils/supabase";
import { userId } from "../App";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import {green, red} from "@ant-design/colors";

interface TransactionsPageProps {}

const TransactionsPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());
  const [selectedSalesPointId, setSelectedSalesPointId] = useState('');
  const [salesPointsOptions, setSalesPointsOptions] = useState<any[]>([]);

  useEffect(() => {

    setLoading(true)

  async function getData() {
    const { data: salesPoints, error: errorSalesPoints } = await supabase
        .from("sales_points")
        .select("name, id")
        .eq("shop_id", userId.value);
    if (errorSalesPoints) {
      throw Error();
    } else {
      const salesPointsOptions = salesPoints.map((point: any) => ({
        value: point.id,
        label: point.name,
      }));
      setSalesPointsOptions([
        { value: "", label: "Tutti i punti vendita" },
        ...salesPointsOptions,
      ]);
    }
    if(selectedSalesPointId){
      const { data, error } = await supabase
          .from("transactions")
          .select("created_at, sales_point_name, type, points, euro")
          .eq("shop_id", userId.value)
          .eq('sales_point_id', selectedSalesPointId)
          .gt("created_at", selectedDate.endOf('day').subtract(1, 'day').toISOString())
          .lt("created_at", selectedDate.endOf('day').toISOString())
          .order("created_at", { ascending: false });
      if(error){
        console.log(error)
      }

      if (data) {
        setData(data);
      }
      setLoading(false);

    }else {
      const { data, error } = await supabase
          .from("transactions")
          .select("created_at, sales_point_name, type, points, euro")
          .eq("shop_id", userId.value)
          .gt("created_at", selectedDate.endOf('day').subtract(1, 'day').toISOString())
          .lt("created_at", selectedDate.endOf('day').toISOString())
          .order("created_at", { ascending: false });
      if (data) {
        setData(data);
      }
      setLoading(false);
    }

  }

    getData().then();
  }, [selectedDate, selectedSalesPointId]);

  const columns = [
    {
      title: "Ora",
      dataIndex: "created_at",
      key: "hour",
      render: (value: string) => (
        <p style={{ textTransform: "capitalize" }}>
          {dayjs(value).locale("it").format("HH:MM.ss")}
        </p>
      ),
    },
    {
      title: "Punto Vendita",
      dataIndex: "sales_point_name",
      key: "sales_point_name",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (value: number)=> (value === 0 ? <p style={{backgroundColor: green[2], width: 'fit-content', padding: '1px 10px', borderRadius: 4, color: green[7]}}>Assegnati</p> : <p style={{backgroundColor: red[2], width: 'fit-content', padding: '1px 10px', borderRadius: 4, color: red[7]}}>Promozione</p>)
    },
    {
      title: "Punti",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Importo",
      dataIndex: "euro",
      key: "euro",
      render: (value: number) => <p>{value ? value + "€" : "-"}</p>,
    },
  ];

  const disabledDate: any = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div>
      <Flex gap={10} wrap={'wrap'} style={{ marginBottom: 30 }} justify={"space-between"}>
        <h1>Transazioni</h1>
        <Flex gap={5}>
          <Select
              style={{ minWidth: 200, flex: 1 }}
              placeholder={"Punto vendita"}
              options={salesPointsOptions}
              onSelect={setSelectedSalesPointId}
              value={selectedSalesPointId}
          />
          <DatePicker
              style={{ minWidth: 200, flex: 1 }}
            allowClear={false}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            onChange={setSelectedDate}
            defaultValue={selectedDate}
          />
        </Flex>
      </Flex>
      {!loading && (
        <Table
            rowKey={'created_at'}
            style={{overflow: 'hidden'}}
          pagination={{ pageSize: 100 }}
          size={"small"}
          loading={loading}
          dataSource={data}
          columns={columns}
        />
      )}
    </div>
  );
};
export default TransactionsPage;
