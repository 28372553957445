import imageCompression from "browser-image-compression";

export async function compressImage(imageFile: File): Promise<File | Blob> {
    const options = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
        fileType: 'image/jpeg'
    }
    try {
        return await imageCompression(imageFile, options);
    } catch (error) {
        throw error;
    }
}

// Function to create an object URL from a remote image
export async function createObjectUrlFromRemoteImage(imageUrl: string) {
    try {
        // Fetch the image from the remote URL
        const response = await fetch(imageUrl);

        // Check if the fetch was successful
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Convert the response into a blob
        const blob = await response.blob();

        // Create an object URL from the blob
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Error creating object URL:', error);
    }
}
