export function validatePartitaIVA(partitaIVA: string): boolean {
    // Check if the length is exactly 11 characters and if it contains only digits
    if (!/^\d{11}$/.test(partitaIVA)) {
        return false;
    }

    // Convert the string to an array of digits
    const digits = partitaIVA.split('').map(Number);

    // Calculate the sum based on the validation rule
    let sum = 0;
    for (let i = 0; i < 11; i++) {
        let digit = digits[i];
        if (i % 2 === 0) {
            // Odd positions (0-indexed) are summed directly
            sum += digit;
        } else {
            // Even positions are doubled, and summed digit by digit if the result is > 9
            let doubled = digit * 2;
            sum += doubled > 9 ? doubled - 9 : doubled;
        }
    }

    // The checksum is valid if the sum modulo 10 is 0
    return sum % 10 === 0;
}
