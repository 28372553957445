import {Button, Flex, Form, Input, message, Modal, Select, Space, Switch, theme, Tooltip} from "antd";
import PriceListSettings from "../components/priceList/PriceListSettings";
import '../styles/priceList.css'
import {useState} from "react";
import {COLORS} from "../utils/colors";
import {IconDots} from "@tabler/icons-react";
import {v4 as uuidv4} from "uuid";
import ItemCard from "../components/priceList/ItemCard";
import {AnimatePresence, motion} from "framer-motion";
import AddItemButtons from "../components/priceList/AddItemButtons";
import PhonePreviewFrame from "../components/PhonePreviewFrame";
import PriceListPreview from "../components/priceList/PriceListPreview";
import FixedSaveButton from "../components/FixedSaveButton";
import {z} from "zod";
import {useForm} from "antd/es/form/Form";
import ItemFormModal from "../components/priceList/ItemFormModal";
import {useMutation, useQuery} from "@tanstack/react-query";
import {apiV1} from "../utils/api";
import PageLoader from "../microcomponents/PageLoader";
import {completed, publishedComputed, queryClient} from "../App";
import {supabase} from "../utils/supabase";
import Wrapper from "../components/Wrapper";
import {
    PriceListDraft, PriceListItem, PriceListSource,
    PriceListThemes,
    priceListThemesOptions,
    PriceListTypes
} from "@virlewood/nif-types";
import {green, red} from "@ant-design/colors";
import {EyeFilled, EyeInvisibleFilled} from "@ant-design/icons";


const PriceListPage = () => {

    const [focusedId, setFocusedId] = useState('');
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [mobilePreviewOpen, setMobilePreviewOpen] = useState(false);
    const [linkFormTouched, setLinkFormTouched] = useState(false)
    const [formItemOptions, setFormItemOptions] = useState<{
        action: 'edit' | 'addBelow' | 'addAbove',
        fromId: string,
        item: PriceListItem
    } | undefined>();
    const [form] = useForm()
    const link = Form.useWatch('link', form)


    const {data: draft, isLoading: isDraftLoading} = useQuery({
        queryKey: ['price-list'], queryFn: async () => {
            return await apiV1.get<'no-draft' | PriceListDraft>('/price-lists/draft')
        }
    })

    const createNewDraft = useMutation({
        mutationFn: async ({type, source, theme}: {
            type: PriceListTypes,
            source: PriceListSource,
            theme: PriceListThemes
        }) => {
            const priceList: PriceListDraft = {
                type: type,
                items: [],
                source: source,
                theme: theme,
                published: true
            }
            await apiV1.post('/price-lists/draft', priceList)
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['price-list']})
        },
    })

    const updateDraft = useMutation({
        onMutate: async (nextPriceList: PriceListDraft) => {
            await queryClient.cancelQueries({queryKey: ['price-list']})
            const previousTodos = queryClient.getQueryData(['price-list'])
            queryClient.setQueryData(['price-list'], () => nextPriceList)
            return {previousTodos}
        },
        mutationFn: async (nextPriceList: PriceListDraft) => {
            await apiV1.put('/price-lists/draft', nextPriceList)
        }, onSettled: async () => {
            await queryClient.invalidateQueries({queryKey: ['price-list']})
        },
    })

    const publish = useMutation({
        mutationFn: async () => {
            if (!draft || draft == 'no-draft') {
                return
            }
            const body: PriceListDraft = {...draft, link}
            await apiV1.put('/price-lists/publish', body)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['price-list']})
            setLinkFormTouched(false)
            message.success("Modifiche pubblicate")
        }
    })

    async function removeItem(itemToRemove: PriceListItem) {
        if (draft === 'no-draft' || !draft) return
        setFormItemOptions(undefined)
        const nextItems: PriceListItem[] = draft.items.filter(item => item.id !== itemToRemove.id);
        updateDraft.mutate({...draft, items: nextItems})
        if (itemToRemove.data.imagePath) {
            await supabase.storage.from('main').remove([itemToRemove.data.imagePath])
        }
    }

    function onSaveItem(values: PriceListItem) {
        if (!formItemOptions || draft === 'no-draft' || !draft) return

        let nextItems: PriceListItem[] = [];
        if (formItemOptions.action !== 'edit') {
            let index = draft.items.findIndex(item => item.id === formItemOptions.fromId);
            if (formItemOptions.action === 'addBelow') {
                index = index + 1;
            }

            nextItems = [
                ...draft.items.slice(0, index),
                {...values},
                ...draft.items.slice(index)
            ];
            setFocusedId(values.id)
        } else {
            let index = draft.items.findIndex(item => item.id === values.id);
            nextItems = [...draft.items]
            nextItems[index].data = values.data
        }
        updateDraft.mutate({...draft, items: nextItems})
        setFormItemOptions(undefined)
    }

    if (!draft || isDraftLoading || createNewDraft.isPending) {
        return <PageLoader/>
    }

    const isLastItemFocused = draft != 'no-draft' && draft.items[draft.items.length - 1]?.id === focusedId

    return (
        <div style={{maxWidth: 1400, margin: "auto", height: mobilePreviewOpen ? '80vh' : 'auto', overflowY: 'hidden'}}>
            {draft === 'no-draft' ? (
                <Flex align={'center'} justify={'center'} style={{height: '80vh', width: '100%'}}>
                    <Wrapper style={{maxWidth: 600, width: '100%'}}>
                        <PriceListSettings onSave={createNewDraft.mutate}/>
                    </Wrapper>
                </Flex>
            ) : (
                <Flex>
                    <div style={{maxWidth: 600, margin: "auto", width: '100%', minHeight: '80vh'}}>
                        <Flex justify={'space-between'}
                              style={{
                                  borderBottom: `1px solid ${COLORS.lightGray}`,
                                  padding: 15,
                                  position: 'sticky',
                                  top: 0,
                                  backgroundColor: 'white',
                                  zIndex: 99
                              }}>
                            <Flex gap={5} align={'center'}>
                                <h2>{draft.type === 'menu' ? 'Menù' : 'Listino prezzi'}</h2>
                            </Flex>
                            <Flex gap={5}>
                                {
                                    draft.source === 'editor' &&
                                    <Select className={'hide-mob'} options={priceListThemesOptions}
                                            onChange={(value) => updateDraft.mutate({...draft, theme: value})}
                                            value={draft.theme}/>
                                }
                                <Button style={{aspectRatio: 1, padding: 0}} onClick={() => setSettingsModalOpen(true)}><IconDots
                                    size={14}/></Button>
                            </Flex>
                        </Flex>
                        {draft.source === 'link' ?
                            <Form form={form} onChange={() => !linkFormTouched ? setLinkFormTouched(true) : {}}
                                  initialValues={{link: draft.link}} onFinish={publish.mutate}
                                  style={{marginTop: 20, marginBottom: 20}} layout={'vertical'}>
                                <Form.Item required={false} name={'link'} validateTrigger="onChange" rules={[{
                                    validator: (_, value) => {
                                        try {
                                            z.string().url().parse(value)
                                            return Promise.resolve()
                                        } catch (_) {
                                            return Promise.reject('Url non valido')
                                        }
                                    },
                                    required: true
                                }]} label={'Link al menù'} hasFeedback><Input
                                    placeholder={'Link al Menù'}/></Form.Item>
                            </Form>
                            :
                            <>
                                <AnimatePresence initial={false}>
                                    {
                                        draft.items.map((item, index) => (
                                            <ItemCard theme={draft.theme} onEdit={(options) => {
                                                setFormItemOptions(options)
                                            }} onRemove={removeItem}
                                                      addBlock={(id, position, type) => setFormItemOptions({
                                                          action: position,
                                                          fromId: id,
                                                          item: {type, id: uuidv4(), data: {name: ''}}
                                                      })} key={item.id} item={item}
                                                      focused={focusedId} setFocused={setFocusedId}/>
                                        ))
                                    }
                                </AnimatePresence>
                                <AnimatePresence>
                                    {!isLastItemFocused && (
                                        <motion.div initial={{opacity: 0, y: -30}} animate={{opacity: 1, y: 0}}>
                                            <AddItemButtons open={true} down onClick={(type) => {
                                                setFormItemOptions({
                                                    action: 'addBelow',
                                                    fromId: '',
                                                    item: {type, id: uuidv4(), data: {name: ''}}
                                                })
                                            }}/>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </>
                        }
                        <FixedSaveButton disabledText={'Tutte le modifiche sono pubbliche'} loading={publish.isPending}
                                         disabled={!linkFormTouched && draft.published}
                                         onClick={draft.source === 'link' ? form.submit : publish.mutate}
                                         onClickPreview={() => setMobilePreviewOpen(true)}/>
                    </div>

                    <Flex onClick={() => setFocusedId('')} className={'hide-xl'} justify={'center'}
                          style={{position: 'sticky', maxWidth: 600, width: '100%', top: 20}}>
                        <PhonePreviewFrame>
                            <PriceListPreview source={draft.source} link={link} type={draft.type} theme={draft.theme}
                                              items={draft.items}/>
                        </PhonePreviewFrame>
                    </Flex>
                    {formItemOptions &&
                        <ItemFormModal removeItem={removeItem} onCancel={() => setFormItemOptions(undefined)}
                                       formOptions={formItemOptions}
                                       onFinish={onSaveItem}/>
                    }
                    <Modal open={settingsModalOpen} onCancel={() => setSettingsModalOpen(false)} footer={false}
                           closeIcon={false}>
                      {/*  <div style={{
                            padding: 20,
                            borderRadius: 10,
                            backgroundColor: publishedComputed.value ? green[0] : red[0],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderColor: publishedComputed.value ? green[2] : red[2],
                            borderWidth: '1px',
                            borderStyle: 'solid'
                        }}>
                            <Space size={'middle'}>
                                {publishedComputed.value ? <><EyeFilled style={{fontSize: 25, color: green[6]}}/>
                                    <div style={{color: green[6]}}>
                                        <h3>Il tuo menù è pubblico</h3>
                                        <p>Il tuo menù è visibile nell'app</p>
                                    </div>
                                </> : <><EyeInvisibleFilled style={{fontSize: 25, color: red[2]}}/>
                                    <div style={{color: red[5]}}>
                                        <h3>Il negozio è nascosto</h3>
                                        <p>Pubblica il negozio e fatti trovare nell'app</p>
                                    </div>
                                </>}
                            </Space>
                            <Tooltip placement={'top'}
                                     title={!completed.value && 'Completa il profilo per poter pubblicare'}>
                                <Switch onChange={() => {
                                }} checked={publishedComputed.value}
                                        style={{backgroundColor: publishedComputed.value ? green[6] : 'grey'}}
                                        unCheckedChildren={'Pubblica'} checkedChildren={'Nascondi'}
                                        disabled={!completed.value}/>
                            </Tooltip>
                        </div>*/}
                        <PriceListSettings priceList={draft} onSave={(props) => {
                            updateDraft.mutate({...draft, ...props});
                            setSettingsModalOpen(false)
                        }}/>
                    </Modal>
                    {mobilePreviewOpen &&
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            width: '100%',
                            zIndex: 1000,
                            backgroundColor: 'white',
                            left: 0,
                            height: '100vh',
                            overflowY: 'scroll'
                        }}>
                            <Button onClick={() => setMobilePreviewOpen(false)} type={'primary'}
                                    style={{position: 'fixed', top: 20, right: 15}}>Esci dall'anteprima</Button>
                            <PriceListPreview link={link} source={draft.source} type={draft.type} theme={draft.theme}
                                              items={draft.items}/>
                        </div>
                    }
                </Flex>
            )}
        </div>
    );
}
export default PriceListPage;
