import { Button, Flex, Input, Modal, Space } from "antd";
import ConfirmButton from "./ConfirmButton";
import { Buffer } from "node:buffer";
import {ReactNode, useState} from "react";
import {AlertFilled, AlertOutlined, CheckCircleOutlined, WarningOutlined} from "@ant-design/icons";
import {green} from "@ant-design/colors";

interface ConfirmModalProps {
  open: boolean,
  loading: boolean,
  setOpen: (open: boolean)=>void,
  danger: boolean;
  title: string;
  buttonText: string;
  onConfirm: () => void;
  children: ReactNode;
}

const ConfirmModal = ({
    open,
    loading,
    setOpen,
  danger,
  title,
  buttonText,
  onConfirm,
  children,

}: ConfirmModalProps) => {

  const [inputValue, setInputValue] = useState('');

  function checkSafeDelete(){
    if(inputValue.toLowerCase() === 'elimina'){
      onConfirm()
    }
  }

  return (
    <Modal onCancel={()=>setOpen(false)} footer={false} open={open}>
      <Flex vertical align={"center"} style={{textAlign: 'center', textWrap: 'balance', whiteSpace: 'pretty'}}>
        <h1>{danger && <WarningOutlined style={{color: 'red'}}/>} {title}</h1>
        <div style={{padding: 20, width: '100%'}}>
          {children}
        </div>
        {danger ? (
          <Flex vertical gap={10}>
            <p style={{color: 'red'}}>Inserisci "elimina" per confermare</p>
            <Input size={'middle'} style={{height: 30, textAlign: 'center'}} placeholder={'Elimina'} onChange={(e)=>setInputValue(e.target.value)} />
            <Button loading={loading} disabled={inputValue.toLowerCase() !== 'elimina'} onClick={checkSafeDelete} type={'primary'} danger>{buttonText}</Button>
          </Flex>
        ) : (
          <Button style={{backgroundColor: green[5]}} type={'primary'} onClick={onConfirm} loading={loading} ><CheckCircleOutlined/>{buttonText}</Button>
        )}
      </Flex>
    </Modal>
  );
};
export default ConfirmModal;
