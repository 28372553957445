import {Button, Form, Input, message, Select, Skeleton} from "antd";
import {useEffect, useState} from "react";
import {supabase} from "../../utils/supabase";
import {userId} from "../../App";
import Wrapper from "../Wrapper";
import {COLORS} from "../../utils/colors";

const ShopInfoForm = () => {
    const [loading, setLoading] = useState(true);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [disabled, setDisabled] = useState(true);

    const [form] = Form.useForm()

    useEffect(() => {
        async function getData() {

            const {data: options} = await supabase.from('business_categories').select('*').order('italian');
            setCategoriesOptions(options)

            const {
                data: shop,
                error: shopDataError
            } = await supabase.from('shops').select('business_name, vat, business_category(id, italian)').eq('id', userId.value)
            const values= {...shop[0], business_category: shop[0].business_category.id}
            form.setFieldsValue(values)
        }

        getData().then(() => setLoading(false))
    }, [])

    async function onFinish(values){
        setDisabled(true)
        setLoading(true)
        const {_, error} = await supabase.from('shops').update(values).eq('id', userId.value);
        if(error){
            message.error('Qualcosa è andato storto')
            return;
        }

        message.success('Informazioni aggiornate');
        setLoading(false)

    }


    return (
        <Wrapper p={20} disabled={disabled}>
            <Form onFinish={onFinish} form={form} style={{textAlign: 'right'}}
                  layout={'vertical'} disabled={disabled}>
                <h3 style={{marginBottom: 20, textAlign: 'left'}}>Riepilogo</h3>
                {loading ? <div>
                        <Skeleton active/>
                    </div> :
                    <><Form.Item name={'business_name'} label={'Nome del negozio'}>
                        <Input size={'large'}/>
                    </Form.Item>
                        <Form.Item name={'vat'} label={'Partita IVA'}>
                            <Input size={'large'}/>
                        </Form.Item>
                        <Form.Item name={'business_category'} label={'Categoria'}>
                            <Select style={{textAlign: 'left'}} size={'large'}>
                                {categoriesOptions.map((item)=>(
                                    <Select.Option key={item.id} value={item.id}>{item.italian}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {disabled ? <Button type={'primary'} onClick={()=>setDisabled(false)} disabled={false}>Modifica</Button>:<Button style={{borderRadius: 30}} onClick={()=>form.submit()} type={'primary'}>Salva</Button>}
                    </>}

            </Form>
        </Wrapper>

    );
}
export default ShopInfoForm;
