import {CSSProperties, ReactNode} from "react";
import {COLORS} from "../utils/colors";

interface WrapperProps {
}

const Wrapper = ({children, style, p = 20, mt = 0, disabled = false}: { children: ReactNode, style?: CSSProperties, p?: number, mt?: number, disabled?: boolean }) => {
    return (
        <div style={{ backgroundColor: disabled ? COLORS.veryLightGray : 'transparent', padding: p, marginTop: mt, border: `1px solid ${COLORS.lightGray}`, borderRadius: 10, ...style}}>
            {children}
        </div>);
}
export default Wrapper;
