import Wrapper from "../Wrapper";
import {Button, Flex, Modal, Radio} from "antd";
import {useState} from "react";
import {COLORS} from "../../utils/colors";
import {
    IconArrowRight,
    IconCoinEuro,
    IconEdit,
    IconLink,
    IconList,
    IconSalad,
    IconToolsKitchen2
} from "@tabler/icons-react";
import RadioButtons from "../RadioButtons";
import {
    PriceList,
    PriceListSource,
    PriceListThemes,
    priceListThemesOptions, PriceListTypes,
    priceListTypesOptions
} from "../../types/PriceListTypes";
import {AnimatePresence, motion} from "framer-motion";
import {PriceListDraft} from "@virlewood/nif-types";

interface PriceListSettingsProps {
}

const PriceListSettings = ({
                               priceList,
                               onSave
                           }: {
                               priceList?: PriceListDraft;
                               onSave: ({type, source, theme}: {
                                   type: PriceListTypes,
                                   source: PriceListSource,
                                   theme: PriceListThemes
                               }) => void,
                           }
) => {
    const [type, setType] = useState<PriceListTypes>(priceList?.type || 'menu' );
    const [source, setSource] = useState<PriceListSource>(priceList?.source ||'editor');
    const [theme, setTheme] = useState<PriceListThemes>(priceList?.theme ||'modern');

    const previewData = {
        menu: {
            section: 'Antipasti',
            name: 'Pasta allo scoglio',
            details: 'Pasta, acciughe'
        },
        'price-list': {
            section: 'Servizi',
            name: 'Taglio uomo',
            details: ''
        }
    }

    const menuSourceOptions = [
        {label: 'Crea', value: 'editor', icon: <IconEdit/>},
        {label: 'Link', value: 'link', icon: <IconLink/>},
    ]


    return (
        <>
            <h3 style={{marginTop: 15, marginBottom: 5}}>Tipo</h3>
            <RadioButtons options={priceListTypesOptions} onSelect={setType} selectedValue={type}/>
            <h3 style={{marginTop: 15, marginBottom: 5}}>Fonte</h3>
            <RadioButtons options={menuSourceOptions} onSelect={setSource}
                          selectedValue={source}/>
            <div style={{marginTop: 5, marginBottom: 20}}>
                {
                    source === 'editor' ?
                        <p>Crea la lista attraverso il nostro editor (Consigliato)</p> :
                        <p>Inserisci il link ad una lista già esistente.</p>
                }
            </div>
            <AnimatePresence initial={false}>
                {
                    source === 'editor' &&
                    <motion.div initial={{opacity: 0, height: 0,}}
                                animate={{opacity: 1, height: 'fit-content', y: 0}}>
                        <h3 style={{marginTop: 15, marginBottom: 5}}>Tema</h3>
                        <RadioButtons options={priceListThemesOptions} onSelect={setTheme} selectedValue={theme}/>
                        <Wrapper style={{marginTop: 10, marginBottom: 10}}>
                            <div>
                                <p className={`section-title ${theme}`}>{previewData[type].section}</p>
                                <div className={`section-divider ${theme}`}/>
                                <Flex align={'center'} justify={'space-between'}>
                                    <div>
                                        <p className={`item-name ${theme}`}>{previewData[type].name}</p>
                                        <p className={`item-description ${theme}`}>{previewData[type].details}</p>
                                    </div>
                                    <p className={`item-price ${theme}`}>25€</p>
                                </Flex>
                            </div>
                        </Wrapper>
                    </motion.div>
                }
            </AnimatePresence>
            <Flex justify={'end'}>
                <Button onClick={() => {
                    onSave({type, source, theme})
                }} style={{marginTop: 15}}
                        size={'large'}
                        type={'primary'}>Crea {type === 'menu' ? 'Menù' : 'Listino prezzi'}
                    <IconArrowRight/>
                </Button>
            </Flex>
        </>
    );
}
export default PriceListSettings;


