import {Badge, Button, Collapse, ConfigProvider, Flex, Input, Modal, Select, Tag} from "antd";
import {useState} from "react";
import {IconEdit, IconEggs, IconX} from "@tabler/icons-react";
import {COLORS} from "../../utils/colors";
import {allergens, allergensObject, PriceListItem, PriceListItemData} from "../../types/PriceListTypes";
import {PriceListThemes} from "@virlewood/nif-types";

interface PriceListItemInputProps {
}

const {TextArea} = Input;

const PriceListItemCard = ({theme, setFocused, item, focusedId}: {
    theme: PriceListThemes,
    setFocused: (focusedId: string) => void,
    item: PriceListItem,
    focusedId: string,
}) => {
    const tagsData = ['Pesce', 'Molluschi', 'Latticini', 'Glutine', 'Frutta a guscio', 'Crostacei', 'Arachidi', 'Lupini', 'Uova', 'Solfiti', 'Soia', 'Sesamo', 'Senape', 'Sedano'];

    const handleChange = (tag: string, checked: boolean) => {
        const data = item.data.allergens || []
        const nextSelectedTags = !checked
            ? [...data, tag]
            : data.filter((t: string) => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
    };

    const [open, setOpen] = useState(false);

    return (
        <ConfigProvider theme={{
            components: {
                Collapse: {
                    contentPadding: '0px',
                    padding: 0,
                    headerBg: 'white',
                    headerPadding: '0px',
                    contentBg: 'white',
                    colorBorder: 'white',
                    fontSize: 13
                }
            }
        }}>
            <div>
                <div>
                    <Flex gap={5} style={{marginBottom: 5}} align={'flex-start'}>
                        <div style={{flex: 1}}>
                            <p className={`${theme}-theme`} style={{flex: 6, fontSize: 16, fontWeight: 600}}>
                                {item.data.name}
                            </p>
                           {/* <TextArea onChange={(e) => onChange(item.id, 'name', e.target.value)} value={item.data.name}
                                      autoFocus autoSize onFocus={() => setFocused(item.id)}
                                      className={'item-input'}
                                      style={{flex: 6, fontSize: 16, fontWeight: 600}} placeholder={'Nome prodotto'}/>*/}
                        </div>
                        <Flex gap={3} align={'center'}>
                           {/* <input className={'item-input'} placeholder={'Prezzo'}
                                   onChange={(e) => onChange(item.id, 'price', e.target.value)} value={item.data.price}
                                   style={{textAlign: 'right', fontSize: 16, width: 70}}/>*/}
                            <p className={`item-price ${theme}`} style={{textAlign: 'right', fontSize: 16, width: 70}}>{item.data.price}</p>
                            {item.data.price &&
                                <p style={{fontSize: 16}}>€</p>
                            }
                        </Flex>

                    </Flex>
                    <p>
                        {item.data.description}
                    </p>
                </div>
            </div>
        </ConfigProvider>
    );
}
export default PriceListItemCard;
