import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useLocation } from "react-router-dom";
import { Card } from "antd";

const FinishSignUpPage = () => {
  const [error, setError] = useState(true);

  const location = useLocation();

  useEffect(() => {
    let searchParams = new URLSearchParams(location.hash);
    if (searchParams.get("#error")) {
      setError(true);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <Card>
          <h4 style={{ color: "red" }}>Codice non valido</h4>
        </Card>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default FinishSignUpPage;
