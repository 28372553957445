import { AnimatePresence, motion } from "framer-motion";
import { Flex } from "antd";
import {PaymentFormProps} from "./onBoarding/PaymentForm";
import {SubscriptionType} from "@virlewood/nif-types";

interface SummaryProps {
  subscriptionType: "monthly" | "yearly";
  salesPointsCount: number;
  disclaimer?: boolean;
}

function getDateAfterXDays(days: number) {
  // Get the current date
  let currentDate = new Date();

  // Add 30 days to the current date
  currentDate.setDate(currentDate.getDate() + days);

  // Return the new date
  return currentDate;
}

const Summary = (props: SubscriptionType) => {

    if(!props.invoice) return <div/>

  return (
    <div style={{ marginTop: 20 }}>
      <AnimatePresence mode={"wait"}>
        {props.interval === "month" ? (
          <motion.div
            key={"m"}
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.3 }}
          >
            <Flex justify={"space-between"}>
              <b>Abbonamento Mensile:</b>
              <p>49€</p>
            </Flex>
          </motion.div>
        ) : (
          <motion.div
            key={"r"}
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.3 }}
          >
            <Flex justify={"space-between"}>
              <b>Abbonamento Annuale:</b>
              <p>{props?.subscription_amount}€</p>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
        <AnimatePresence mode={"wait"}>
            {props.invoice.discount_amount &&
                <motion.div
                    key={"m"}
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -20, opacity: 0 }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                >
                    <Flex justify={"space-between"}>
                        <b>Promozione:</b>
                        <p>-{props.invoice.discount_amount/100}€</p>
                    </Flex>
                </motion.div>
            }
        </AnimatePresence>

      <Flex justify={"space-between"}>
        <b>{props.extra_sales_points_number} punti vendita Extra:</b>
        <AnimatePresence mode={"wait"}>
            <motion.div
              key={"t"}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.3, delay: 0.05 }}
            >
                {props.extra_sales_points_subscriptions_amount ?
                    <p>{props?.extra_sales_points_subscriptions_amount * props.extra_sales_points_number}€</p>:
                    <p>0€</p>
                }
            </motion.div>
        </AnimatePresence>
      </Flex>

      <div style={{ width: "100%", height: 1, backgroundColor: "gray" }} />
      <Flex justify={"space-between"}>
        <b></b>
        <AnimatePresence mode={"wait"}>
            <motion.div
              key={"t"}
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -30, opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.3, delay: 0.1 }}
            >
                <p>{props.invoice.total_excluding_tax/100}€</p>
            </motion.div>
        </AnimatePresence>
      </Flex>
      <Flex justify={"space-between"}>
        <b>IVA</b>
        <AnimatePresence mode={"wait"}>
            <motion.div
              key={"t"}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.3, delay: 0.15 }}
            >
              <p>
               22%
              </p>
            </motion.div>
        </AnimatePresence>
      </Flex>
      <Flex justify={"space-between"}>
        <b>Totale</b>
        <AnimatePresence mode={"wait"}>
            <motion.div
              key={"t"}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.3, delay: 0.2 }}
            >
              <p>
                  {props.invoice?.total / 100}€
              </p>
            </motion.div>
        </AnimatePresence>
      </Flex>
    {/*  {disclaimer && (
        <p style={{ marginTop: 20 }}>
          Provalo gratis per 30 giorni, puoi inserire il metodo di pagamento
          successivamente. Il costo dell'abbonamento sarà addebitato il{" "}
          <b>{getDateAfterXDays(30).toLocaleDateString()}</b> e si rinnoverà
          automaticamente ogni{" "}
          <span>{subscriptionType === "yearly" ? "anno" : "mese"}</span>.
          <p>Puoi annullare l'abbonamento quando vuoi.</p>
        </p>
      )}*/}
    </div>
  );
};
export default Summary;
