import OnBoardLayout from "../layouts/OnBoardLayout";
import {Button, Card, Flex, Input, message} from "antd";
import {useLayoutEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {z} from "zod";
import {supabase} from "../utils/supabase";
import {COLORS} from "../utils/colors";
import {trackEvent} from "../utils/Matomo/tracking_functions";

interface ConfirmEmailPageProps {
}

const ConfirmEmailPage = () => {
    const [loading, setLoading] = useState(false);
    const [opt, setOpt] = useState<string>("");

    const {state} = useLocation();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        try {
            z.string().email().parse(state);
        } catch (e) {
            navigate("/sign-up");
        }
    }, []);

    async function verifyCode() {
        setLoading(true);
        const {data, error} = await supabase.auth.verifyOtp({
            email: state,
            token: opt,
            type: "email",
        });
        if (error) {
            message.error("Codice errato");
            setLoading(false);
            return;
        }
        if (data) {
            trackEvent('User Registration', 'OTP Verified', state)
            window.location.reload();
        }
    }

    async function resend() {
        const {data, error} = await supabase.auth.resend({
            type: 'signup',
            email: state,
            options: {
                emailRedirectTo: "https://app.nextisfree.io/welcome/"
            }
        })
    }

    if (state)
        return (
            <OnBoardLayout>
                <Card
                    style={{
                        backgroundColor: "white",
                        width: "90%",
                        maxWidth: 580,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >

                    <h1 style={{textAlign: 'center'}}>Verifica indirizzo email</h1>
                    <p style={{
                        marginTop: 0,
                        fontSize: 14,
                        textAlign: 'center',
                        marginBottom: 30,
                        color: COLORS.gray,
                        lineHeight: 1.5
                    }}>
                        Ti abbiamo inviato una email a: {state} per verificare il tuo
                        indirizzo email. Clicca su "Verifica Email" per continuare o inserisci il codice di verifica.
                    </p>
                    <Flex data-matomo-form={true} data-matomo-name="emailVerificationOTP" gap={10}
                          style={{marginTop: 10, flexDirection: 'column'}} align={'center'}>
                        <Input.OTP
                            style={{width: 260}}
                            size={'large'}
                            length={6}
                            onChange={(value) => {
                                setOpt(value);
                            }}
                        />
                        <Button onClick={verifyCode} style={{width: 260}} size={'large'}
                                type={'primary'}>Verifica</Button>
                    </Flex>
                    <h3 style={{marginTop: 30, textAlign: 'center'}}>Non vedi l'email?</h3>
                    <p style={{textAlign: 'center'}}>
                        Controlla la cartella spam o{" "}
                        <a onClick={resend} style={{color: "black", textDecoration: "underline"}}>
                            invia di nuovo
                        </a>
                    </p>
                </Card>
            </OnBoardLayout>
        );
    return (<div/>)
};
export default ConfirmEmailPage;
