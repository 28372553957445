import {Button, Checkbox, Flex, Form, Input, Modal, Switch} from "antd";
import {allergens, allergensObject, PriceListItem} from "../../types/PriceListTypes";
import {COLORS} from "../../utils/colors";
import {ArrowRightOutlined} from "@ant-design/icons";
import Wrapper from "../Wrapper";
import {green} from "@ant-design/colors";
import SelectAllergens from "./SelectAllergens";
import ImageUploadInput from "../ImageUploadInput";
import {useState} from "react";
import {supabase} from "../../utils/supabase";
import {userId} from "../../App";
import {bucketUrl} from "../../utils/urls";
import {IconTrash} from "@tabler/icons-react";
import {z} from "zod";

interface ItemFormProps {
    formOptions: { action: 'edit' | 'addBelow' | 'addAbove', item: PriceListItem },
    onFinish: (values: PriceListItem) => void,
    onCancel: () => void,
    removeItem: (itemToRemove: PriceListItem) => void
}


const ItemFormModal = ({formOptions, onFinish, onCancel, removeItem}: ItemFormProps) => {

    const [image, setImage] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(formOptions.item.data.imagePath ? `${bucketUrl}/${formOptions.item.data.imagePath}` : undefined);
    const [uploading, setUploading] = useState(false);
    const [form] = Form.useForm()
    const available = Form.useWatch('available', form)

    async function handleSubmit(values: any) {
        let nextValues = values
        if (image) {
            setUploading(true)
            const imagePath = `${userId.value}/price-list/draft/${formOptions.item.id}.jpeg`;
            await supabase.storage.from('main').upload(imagePath, image, {upsert: true})
            nextValues = {...values, imagePath}
            setUploading(false)
        }
        onFinish({...formOptions.item, data: nextValues})
    }

    async function removeImage() {
        if (formOptions.item.data.imagePath) {
            await supabase.storage.from('main').remove([formOptions.item.data.imagePath])
        }
        setImage(undefined)
        setImagePreviewUrl(undefined)
    }

    const {TextArea} = Input

    return (
        <Modal title={<div style={{height: 20}}></div>} onCancel={onCancel} open={true}
               footer={<Flex justify={'space-between'}>
                   {formOptions.action === 'edit' ?
                       <Button onClick={() => removeItem(formOptions.item)}><IconTrash size={16}/></Button> : <div/>}
                   <Button loading={uploading} onClick={form.submit}
                           type={'primary'}>Salva<ArrowRightOutlined/></Button></Flex>}>
            <Form onFinish={handleSubmit} initialValues={formOptions.item.data} form={form} layout={'vertical'}>
                {formOptions.item.type === 'section' && (
                    <ImageUploadInput onRemoveImage={removeImage} imagePreviewUrl={imagePreviewUrl}
                                      setImagePreviewUrl={setImagePreviewUrl} setImage={setImage}/>
                )}
                <Flex gap={8}>
                    <Form.Item required={false} rules={[{required: true, message: 'Il nome non può essere vuoto'}]}
                               style={{flex: 1}} label={'Nome'} name={'name'}>
                        <TextArea autoSize style={{fontWeight: 600}} autoFocus placeholder={'Nome'}/>
                    </Form.Item>
                    {formOptions.item.type === 'item' &&
                        <Form.Item rules={[{
                            validator: (_, value) => {
                                //TODO deve valere anche la virgola
                                if (!z.string().transform((val) => Number(`${val}`.replace(",", "."))).pipe(z.number()).safeParse(value).success) {
                                    return Promise.reject('Numero non valido')
                                } else {
                                    return Promise.resolve()
                                }
                            }
                        }]} style={{maxWidth: 100}} label={'Prezzo'} name={'price'}>
                            <Input suffix={'€'} placeholder={'Prezzo'}/>
                        </Form.Item>
                    }
                </Flex>
                {formOptions.item.type === 'item' &&
                    <>
                        <Form.Item label={'Ingredienti o descrizione'} name={'description'}>
                            <TextArea autoSize placeholder={'Ingredienti o descrizione'}/>
                        </Form.Item>
                        <Form.Item name={'allergens'}>
                            <SelectAllergens/>
                        </Form.Item>
                        <Wrapper style={{padding: 15}}>
                            <Flex align={'center'} justify={'space-between'}>
                                <div>
                                    <p><b>Visibile</b></p>
                                    <p style={{lineHeight: 1, color: COLORS.gray, fontSize: 13}}>Mostra o nascondi
                                        questo prodotto</p>
                                </div>
                                <Form.Item initialValue={true} valuePropName="checked" name={'available'}>
                                    <Switch style={{backgroundColor: available ? green[5] : COLORS.gray}}/>
                                </Form.Item>
                            </Flex>
                        </Wrapper>
                    </>
                }
            </Form>
        </Modal>
    );
}
export default ItemFormModal;
