import {blue, green} from "@ant-design/colors";
import React from "react";
import {CheckOutlined} from "@ant-design/icons";

interface StepCircleIndicatorProps {
    step: string | number,
    done: boolean
}

const StepCircleIndicator = ({step, done}: StepCircleIndicatorProps) => {

    const doneStyle: any = {
        borderRadius: 100,
        border: "3px solid",
        borderColor: green[5],
        padding: 6,
        fontSize: 20,
        width: 40,
        height: 40,
        textAlign: "center",
        backgroundColor: green[5]
    }

    const todoStyle: any = {
        borderRadius: 100,
        color: blue[2],
        fontWeight: 600,
        border: "3px solid",
        borderColor: blue[2],
        padding: 5,
        fontSize: 20,
        width: 40,
        height: 40,
        textAlign: "center",
    }

    return (<div
        style={done ? doneStyle : todoStyle}
    >
        {done ?
            <CheckOutlined style={{
                color: 'white',
                strokeWidth: "30", // --> higher value === more thickness the filled area
                stroke: 'white'
            }}/>
            :
            step
        }
    </div>);
}
export default StepCircleIndicator;
