import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {businessName, userId} from "../../App";

const RouteChangeDetector = () => {
    const location = useLocation();

    useEffect(() => {
        let _paq = window._paq = window._paq || [];
        if(userId.value){
            _paq.push(['setUserId', userId.value]);
        }else{
            _paq.push(['resetUserId']);
        }
        if(businessName.value){
            _paq.push(['setCustomDimension', 1, businessName.value]);
        }
        _paq.push(['setDocumentTitle', location.pathname]);
        _paq.push(['trackPageView']);
        // You can perform any action you need on route change here
    }, [location]);

    return null; // This component doesn't render anything
};

export default RouteChangeDetector;
