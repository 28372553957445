import {PriceListItem, PriceListThemes} from "../../types/PriceListTypes";
import {bucketUrl} from "../../utils/urls";

interface PriceListSectionProps {}

const PreviewPriceListSection = ({item, theme}:{item: PriceListItem, theme: PriceListThemes})=>{
return(
    <div className={`section-title ${theme}`} style={{marginTop: 40}}>
        {item.data.imagePath &&
            <img draggable={false} alt={item.data.name} width={'100%'} style={{borderRadius: 10}} src={bucketUrl + item.data.imagePath}/>
        }
        {item.data.name}
        <div className={`section-divider`}></div>
    </div>
);
}
export default PreviewPriceListSection;
