export const COLORS = {
    white: 'white',
    veryLightGray: '#f7f7f7',
    lightGray: '#efefef',
    mediumGray: '#d8d8d8',
    gray: '#979797',
    darkBg: '#373B44',
    black: '#101921',
    red: '#F22626',
    primary: '#FFc600',
    primaryLight: '#FFE899',
    businessColor: '#1B2441'
}
