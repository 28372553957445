import {Button, Card, Empty, Input, Modal, Popover, Space} from "antd";
import {Link, useNavigate} from "react-router-dom";
import NotificationCard from "../components/NotificationCard";
import {useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import {published, publishedComputed, userId} from "../App";
import {useDebouncedCallback} from "use-debounce";
import Loader from "../components/Loader";
import axios from "axios";
import {createClient} from "@supabase/supabase-js";
import {PlusOutlined} from "@ant-design/icons";
import Wrapper from "../components/Wrapper";

const {Search} = Input;

const NotificationsPage = () => {

    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState();
    const navigate = useNavigate()


    async function getNotifications() {

        try {
            let {data: notifications, error} = await supabase
            .from('notifications')
            .select('*').eq('shop_id', userId.value).order('created_at', {ascending: false})


            if (error) {
                throw error
            }

            setNotifications(notifications)
            setLoading(false)

        } catch (e) {
        }
    }

    async function onSearch(value) {
        setLoading(true)

        try {
            let {data: notifications, error} = await supabase
            .from('notifications')
            .select('*').eq('shop_id', userId.value).like('title', '%' + value + '%')

            if (error) {
                throw error
            }


            setNotifications(notifications)
            setLoading(false)

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const debouncedSearch = useDebouncedCallback(
        // function
        (value) => {
            onSearch(value)
        },
        400
    );

    useEffect(() => {
        getNotifications().then()
    }, [refresh])

    return (
        <>
            <div style={{marginBottom: 40}}>
            </div>
            <div className={'customizePageW'}>
                <div style={{maxWidth: '400px', display: "flex", flexDirection: 'column', gap: 30, width: '100%'}}>
                    <Wrapper>
                        <h2 style={{marginBottom: 10}}>Notifiche</h2>
                        <Popover
                            title={!publishedComputed.value && 'Il tuo negozio deve essere pubblico per poter inviare notifiche'}>
                            <Button disabled={!publishedComputed.value} type={"primary"} size={'large'}
                                    style={{width: '100%', marginTop: 20}} onClick={() => {
                                navigate('/notifications/new')
                            }}><PlusOutlined/>Crea una nuova notifica</Button>
                        </Popover>
                    </Wrapper>
                    <Input onChange={(e) => {
                        debouncedSearch(e.target.value)
                    }} placeholder='Cerca per titolo...'/>

                    {/*   <div style={{backgroundColor: blue[0], border: '1px solid', borderColor: blue[1], borderRadius: 5, padding: '0px 6px', display: "flex", justifyContent: "space-between"}}>
                            <p style={{color: blue[4]}}>{1 +' Selezionati'}</p>
                            <Space size={'small'} direction={'horizontal'}>
                                <Button size={'small'}>Annulla</Button>
                                <Button size={'small'} danger>Elimina</Button>
                            </Space>
                        </div>*/}
                    {!loading ? (<>{notifications.map((item, index) => (
                        <NotificationCard setRefresh={setRefresh} index={index} setSelected={setSelected} key={item.id}
                                          item={item}/>))}
                        {!notifications[0] && <Empty

                            description={<p>Ancora nessuna notifica <br/><Link to={'/notifications/new'}>Creane
                                una!</Link></p>}
                        />
                        }</>) : (<><Loader/></>)}

                </div>
            </div>

        </>);
}
export default NotificationsPage;
