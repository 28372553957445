import {Button, Form, Input, message} from "antd";
import {supabase} from "../utils/supabase";
import NotificationPreview from "../components/NotificationPreview";
import {useState} from "react";
import {publishedComputed, userId} from "../App";
import ImageUploadInput from "../components/ImageUploadInput";
import PhonePreviewFrame from "../components/PhonePreviewFrame";
import ConfirmButton from "../components/ConfirmButton";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {compressImage} from "../utils/imageCompression";
import axios from "axios";
import {authPost} from "../utils/authCalls";
import {trackEvent} from "../utils/Matomo/tracking_functions";

const {TextArea} = Input;
const NotificationsPage = () => {

    const navigate = useNavigate()

    const [form] = Form.useForm()
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [saving, setSaving] = useState(false);
    const [image, setImage] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');

    async function sendNotification(values) {
        setSaving(true)

        try {
            const {data: newNotification, error} = await supabase
            .from('notifications')
            .insert([
                {title: values.title, text: values.text, shop_id: userId.value, image: !!image},
            ]).select()
            if (error) {
                throw error
            }

            if(image){
                const compressedImage = await compressImage(image, 1000,'image/jpeg');
                const {data: imageResponse, error: uploadError} =  await supabase.storage.from('main').upload(userId.value +'/' + newNotification[0].id + '.jpeg', compressedImage, {upsert: true});
                if(uploadError){
                    throw uploadError;
                }
                trackEvent('Notifications', 'Image Uploaded', values.title)
            }

            const {data, error: businessNameError} = await supabase.from('shops').select('business_name').eq('id', userId.value)
            if(businessNameError){
                throw Error()
            }


            await authPost('/v1/notifications/send', {title: values.title, body: values.text, topic: userId.value, notification_id: newNotification[0].id, business_name: data[0].business_name})
            trackEvent('Notifications', 'Notification Sent', values.title)
            navigate(-1)
            message.success('Notifica inviata! 🎉');
        } catch (e) {

        } finally {
            setSaving(false)
        }

    }


    return (
        <>
            <div style={{marginBottom: 40}}>
            </div>
            <div className={'customizePageW'}>
                    <>
                        <div style={{width: '100%', maxWidth: 400}}>
                            <Button onClick={()=>navigate(-1)} style={{color: 'grey', marginBottom: 30}} icon={ <ArrowLeftOutlined />}>Indietro</Button>
                            <h1>Crea una nuova notifica</h1>
                            <Form form={form} onFinish={sendNotification} layout={'vertical'}>
                                <Form.Item label={'Immagine'}>
                                    <ImageUploadInput setImage={setImage} image={image} setImagePreviewUrl={setImagePreviewUrl} imagePreviewUrl={imagePreviewUrl} objectFit={'cover'}/>
                                </Form.Item>
                                <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un titolo' }]} label={'Titolo'} name={'title'}>
                                    <Input onKeyDown={e => e.key === 'Enter' ? e.preventDefault() : null } onChange={(e) => {
                                        setTitle(e.target.value)
                                    }} showCount maxLength={100} placeholder={'Titolo'} name={'title'}/>
                                </Form.Item>
                                <Form.Item rules={[{ required: true, message: 'Le notifiche devono avere un testo' }]} label={'Testo'} style={{marginBottom: 30}} name={'text'}>
                                    <TextArea onChange={(e) => {
                                        setText(e.target.value)
                                    }} showCount maxLength={2000} autoSize={{minRows: 3, maxRows: 10}}
                                              placeholder={'Testo'}
                                              name={'title'}/>
                                </Form.Item>
                                <Form.Item>
                                    <ConfirmButton loading={saving} onConfirm={()=>form.submit()} text={'Invia la notifica'} message={'La notifica verrà inviata a tutti i tuoi clienti e non può essere modificata, vuoi continuare?'} okText={'Invia'}/>
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{width: '100%', maxWidth: 350}} className={'previewPhone'}>
                            <PhonePreviewFrame>
                                <NotificationPreview imageUrl={imagePreviewUrl} title={title} text={text}/>
                            </PhonePreviewFrame>
                        </div>
                    </>
            </div>
        </>);
}
export default NotificationsPage;
