import {Button, Form, Input, Select, Space, Switch, Tooltip} from "antd";
import {supabase} from "../utils/supabase";
import {completed, logged, onBoarded, published, publishedComputed, userId, verified} from "../App";
import {blue, gray, green, red} from "@ant-design/colors";
import {EyeFilled, EyeInvisibleFilled, EyeInvisibleOutlined, ShopOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import ShopInfoForm from "../components/settings/ShopInfoForm";
import ChangePassword from "../components/settings/ChangePassword";
import DangerZone from "../components/settings/DangerZone";
import Wrapper from "../components/Wrapper";

const SettingsPage = () => {


    async function handlePublishing(value){
        //optimistic
        published.value = value;
        const {data, error} = await supabase.from('shops').update({public: value}).eq('id', userId.value)
    }


    return (
        <div className='container'>
            <h1 style={{margin: '40px 15px'}}>Impostazioni</h1>
            <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
                <div style={{
                    padding: 20,
                    borderRadius: 10,
                    backgroundColor: publishedComputed.value ? green[0]: red[0],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderColor: publishedComputed.value ? green[2] : red[2],
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }}>
                    <Space size={'middle'}>
                        {publishedComputed.value ? <><EyeFilled style={{fontSize: 25, color: green[6]}}/>
                            <div style={{color: green[6]}}>
                                <h3>Il negozio è pubblico</h3>
                                <p>Il tuo negozio è visibile nell'app</p>
                            </div>
                        </> : <><EyeInvisibleFilled style={{fontSize: 25, color: red[2]}}/>
                            <div style={{color: red[5]}}>
                                <h3>Il negozio è nascosto</h3>
                                <p>Pubblica il negozio e fatti trovare nell'app</p>
                            </div>
                        </>}

                    </Space>
                    <Tooltip placement={'top'} title={!completed.value && 'Completa il profilo per poter pubblicare'}>
                        <Switch onChange={handlePublishing} checked={publishedComputed.value} style={{backgroundColor: publishedComputed.value ? green[6] : 'grey'}} unCheckedChildren={'Pubblica'} checkedChildren={'Nascondi'} disabled={!completed.value}/>
                    </Tooltip>
                </div>
                <ShopInfoForm/>
                <ChangePassword/>
                <DangerZone/>
            </Space>
        </div>);
}
export default SettingsPage;
