import SimpleStatistic from "./charts/SimpleStatistic";
import {useEffect, useState} from "react";
import {supabase} from "../../utils/supabase";
import {userId} from "../../App";
import {allNotificationsCount, transactions} from "../../pages/AnalyticsPage";

interface TotalCustomersNumberProps {}

const TotalNotificationsSend = () => {

  function sumPointsByType(arr: any) {
    return arr.reduce((totalPoints: any, currentObject: any) => {
      // Check if the type is equal to 0
      if (currentObject.type === 0) {
        // Add the points to the total
        return totalPoints + currentObject.points;
      } else {
        // If type is not equal to 0, return the current total without modification
        return totalPoints;
      }
    }, 0); // Initial totalPoints is 0
  }

  const number = sumPointsByType(transactions.value)


  return <SimpleStatistic  title={'Totale notifiche inviate'} value={allNotificationsCount.value}/>;
};
export default TotalNotificationsSend;
