import { supabase } from "../utils/supabase";
import {Button, Flex, Input, message} from "antd";
import { useState } from "react";
import OnBoardLayout from "../layouts/OnBoardLayout";

interface ChangePasswordPageProps {}

const ChangePasswordPage = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function updatePassword() {
      setLoading(true)
    const { data, error } = await supabase.auth.updateUser({
      password: password,
    });
    if(error){
        message.error(error.message)
        setLoading(false)
        return
    }
    if(data){
        message.success("Password updated successfully");
        setTimeout(()=>{window.location.reload()}, 1000)
    }
  }

  return (
      <OnBoardLayout logOutBtn>
          <Flex vertical gap={10} style={{position: 'absolute', right: '50%', top: '50%', backgroundColor: 'white', transform: 'translate(50%, -50%)', padding: 30, borderRadius: 20, textAlign: 'center'}}>
              <h3 style={{marginBottom: 20}}>Nuova password</h3>
              <Input.Password size={'large'}
                  onChange={(e) => {
                      setPassword(e.target.value);
                  }}
                  placeholder={"Nuova password"}
              ></Input.Password>
              <Button loading={loading} type={'primary'} size={'large'} onClick={updatePassword}>Cambia</Button>
          </Flex>
      </OnBoardLayout>

  );
};
export default ChangePasswordPage;
