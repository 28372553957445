import {
    Button,
    Form,
    Input,
    InputNumber,
    message,
    Space,
    Tooltip,
} from "antd";
import LogoUploadInput from "../components/ImageUploadInput";
import {
    PlusOutlined,
    MinusCircleOutlined,
    ArrowRightOutlined,
} from "@ant-design/icons";
import GoalBarPreview from "../components/GoalBarPreview";
import {useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import {completed, userId} from "../App";
import {compressImage} from "../utils/imageCompression";
import PhonePreviewFrame from "../components/PhonePreviewFrame";
import {COLORS} from "../utils/colors";
import {GoalType} from '@virlewood/nif-types'
import {trackEvent} from "../utils/Matomo/tracking_functions";
import PageLoader from "../microcomponents/PageLoader";

const CustomizePage = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [brandColor, setBrandColor] = useState("#c4c4c4");
    const [businessName, setBusinessName] = useState("");
    const [relation, setRelation] = useState<number>(0);
    const [defaultPoints, setDefaultPoints] = useState<number>(0);
    const [businessCategory, setBusinessCategory] = useState();
    const [image, setImage] = useState<File | undefined>(undefined);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(undefined);
    const [goals, setGoals] = useState<GoalType[]>([]);
    const [highestNumber, setHighestNumber] = useState(20);
    const [orderedGoals, setOrderedGoals] = useState<GoalType[]>([]);
    const [colorPickerError, setColorPickerError] = useState(false);
    const [touched, setTouched] = useState(false)
    const [form] = Form.useForm();

    // Handle the beforeunload event
    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            if (touched) {
                e.preventDefault();
                e.returnValue = ''; // For modern browsers, this shows the default confirmation dialog.
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [touched]);


    const initialPoints = Form.useWatch("initial_points", form);

    function sortGoals(goals: GoalType[]) {
        const validatedGoals = goals.filter(
            (item) => item && item?.number && item?.title,
        );
        let nextGoals = validatedGoals.sort((a, b) => a.number - b.number);

        if (nextGoals.length > 0) {
            const highest = nextGoals[nextGoals.length - 1].number;
            setHighestNumber(highest);
            setOrderedGoals(nextGoals);
        } else {
            setHighestNumber(0);
            setOrderedGoals([]);
        }
    }

    function handleOnChange(changedValues: any, values: any) {
        setTouched(true)
        if (changedValues.goals) {
            sortGoals(values.goals);
            setGoals(values.goals);
        }
    }

    async function getInitialData() {
        //check if logo exists
        const {data: images, error: imageError} = await supabase.storage
            .from("main")
            .list(userId.value);

        const files = images?.filter((item) => item.name === "logo.png");

        if (files && files.length > 0) {
            const {
                data: {publicUrl}
            } = supabase.storage
                .from("main")
                .getPublicUrl(userId.value + "/logo.png");
            setImagePreviewUrl(publicUrl + "?" + Math.random());
        }

        let {data: shop, error} = await supabase
            .from("shops")
            .select("*")
            .eq("id", userId.value);
        if (error || !shop) {
            await supabase.auth.signOut();
            return;
        }

        setBrandColor(shop[0]?.brand_color || "#c4c4c4");
        setBusinessName(shop[0].business_name);
        setBusinessCategory(shop[0].businessCategory);
        setGoals(shop[0].goals);
        sortGoals(shop[0].goals);
        form.setFieldsValue(shop[0]);
        setRelation(shop[0]?.points_ratio)
        setLoading(false);
    }

    useEffect(() => {
        getInitialData().then();
    }, []);

    async function onFinish() {
        setSaving(true);
        if (!imagePreviewUrl) {
            message.error("Carica un'immagine")
            setSaving(false);
            return
        }
        if (colorPickerError) {
            message.error("Seleziona un colore valido")
            setSaving(false);
            return
        }
        try {
            if (image) {
                const compressedImage = await compressImage(image);
                const {data: imageResponse, error: uploadError} =
                    await supabase.storage
                        .from("main")
                        .upload(userId.value + "/logo.png", compressedImage, {
                            upsert: true,
                        });
                if (uploadError) {
                    throw uploadError;
                }
            }


            const {data, error} = await supabase
                .from("shops")
                .update({
                    brand_color: brandColor,
                    points_ratio: relation,
                    initial_points: defaultPoints,
                    goals: goals,
                    completed: true,
                    latest_update_at: new Date().toISOString()
                })
                .eq("id", userId.value)
                .select();

            if (error) {
                throw error;
            }
            trackEvent('Profile Customization', 'Saved', businessName)
            message.success("Le modifiche sono state pubblicate");
            completed.value = true;
            setTouched(false)
        } catch (e) {
        } finally {
            setSaving(false);
        }
    }

    if (loading)
        return (
            <PageLoader/>
        );

    return (
        <>
            <div className={"customizePageW"}>
                <div style={{maxWidth: "400px"}}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={() => message.error("Completa tutti i campi")}
                        onValuesChange={handleOnChange}
                    >
                        <h1>Personalizza</h1>
                        <Form.Item rules={[{required: true}]} label={"logo"}>
                            <LogoUploadInput
                                objectFit={"cover"}
                                setImagePreviewUrl={setImagePreviewUrl}
                                imagePreviewUrl={imagePreviewUrl}
                                image={image}
                                setImage={(props: any)=>{setImage(props); setTouched(true)}}
                                setColor={(props: any)=> {
                                    setBrandColor(props);
                                    setTouched(true)
                                }}
                                color={brandColor}
                                colorPickerError={colorPickerError}
                                setColorPickerError={setColorPickerError}
                            />
                        </Form.Item>
                        <div
                            style={{
                                marginTop: 15,
                                padding: 10,
                                borderRadius: 10,
                                marginBottom: 15,
                                border: `1px solid ${COLORS.lightGray}`,
                            }}
                        >
                            <Form.Item
                                rules={[{required: true}]}
                                name="points_ratio"
                                label={"Rapporto euro/punti"}
                            >
                                <InputNumber
                                    disabled={saving || loading}
                                    addonBefore={"Un euro equivale a"}
                                    addonAfter={"Punti"}
                                    min={0.1}
                                    onChange={(value) => {
                                        if (value) {
                                            setRelation(value);
                                        }
                                    }}
                                    value={relation}
                                    name={"relation"}
                                    placeholder={"Rapporto euro"}
                                    style={{flex: 1}}
                                />
                            </Form.Item>
                            <p>
                                <b>10€</b> <ArrowRightOutlined/>{" "}
                                <b>{Math.round(relation * 10)} punti</b>
                            </p>
                            <p>
                                <b>25€</b> <ArrowRightOutlined/>{" "}
                                <b>{Math.round(relation * 25)} punti</b>
                            </p>
                            <p>
                                <b>50€</b> <ArrowRightOutlined/>{" "}
                                <b>{Math.round(relation * 50)} punti</b>
                            </p>
                        </div>
                        <Space>
                            <Form.Item
                                label={"Punti iniziali"}
                                name="initial_points"
                                rules={[
                                    {
                                        required: true,
                                        message: "Inserisci un valore tra 0 e 5000",
                                    },
                                ]}
                            >
                                <InputNumber
                                    disabled={saving || loading}
                                    value={defaultPoints}
                                    onChange={(e) => e !== null && setDefaultPoints(e)}
                                    defaultValue={defaultPoints}
                                    style={{width: "100%"}}
                                    placeholder="Punti iniziali"
                                />
                            </Form.Item>
                            <Tooltip
                                title="I punti iniziali che il cliente riceve la prima volta che aggiunge il tuo negozio.">
                                <a>Cosa sono?</a>
                            </Tooltip>
                        </Space>
                        <p>Traguardi</p>
                        {/*@ts-ignore*/}
                        <Form.List required rules={[{required: true}]} name="goals">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: 10,
                                                marginBottom: 15,
                                            }}
                                            key={key}
                                        >
                                            <Form.Item
                                                noStyle
                                                {...restField}
                                                name={[name, "title"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Inserire il nome del premio",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={saving || loading}
                                                    autoComplete="off"
                                                    maxLength={30}
                                                    style={{width: "100%"}}
                                                    placeholder="Nome del premio"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                noStyle
                                                {...restField}
                                                name={[name, "number"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Inserire il nome del premio",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    disabled={saving || loading}
                                                    min={1}
                                                    max={5000}
                                                    style={{width: "100%"}}
                                                    placeholder="Punti richiesti"
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => !saving && remove(name)}/>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            disabled={goals.length > 3 || saving || loading}
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined/>}
                                        >
                                            Aggiungi obiettivo
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <p style={{marginBottom: 80}}>
                            Puoi inserire fino a 4 traguardi, il punteggio massimo per un
                            traguardo è 5000
                        </p>
                        <div className={'fixed-save-button'} style={{
                            marginTop: 50,
                            position: 'fixed',
                            maxWidth: 400,
                            backgroundColor: 'white',
                            padding: '20px 1px',
                            height: 70,
                            boxShadow: 'none'
                        }}>
                            <Button
                                disabled={!touched}
                                loading={saving}
                                onClick={() => {
                                    form.submit();
                                }}
                                type={"primary"}
                                size={"large"}
                                style={{width: "100%"}}
                            >
                                Salva le modifiche
                            </Button>
                        </div>
                    </Form>
                </div>
                <PhonePreviewFrame>
                    <GoalBarPreview
                        initialPoints={initialPoints}
                        highestNumber={highestNumber}
                        businessCategory={businessCategory}
                        businessName={businessName}
                        imagePreviewUrl={imagePreviewUrl}
                        goals={orderedGoals}
                        brandColor={brandColor}
                    />
                </PhonePreviewFrame>
            </div>
        </>
    );
};
export default CustomizePage;
