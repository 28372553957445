import {Flex} from "antd";
import {COLORS} from "../utils/colors";

interface RadioButton {
    label: string,
    icon: React.ReactNode,
    value: string,
    setValue: (value: any) => void,
    selected: boolean
}

function Button({label, icon, value, setValue, selected}: RadioButton) {
    return (
        <Flex gap={5} align={'center'} onClick={() => setValue(value)} style={{
            borderColor: selected ? 'black' : COLORS.lightGray,
            cursor: 'pointer',
            padding: 10,
            borderRadius: 10,
            borderWidth: 2,
            borderStyle: 'solid',
            color: selected ? 'white' : 'black',
            backgroundColor: selected ? 'black' : COLORS.lightGray,
        }}>
            {icon}
            <p>{label}</p>
        </Flex>
    )
}

function RadioButtons({options, onSelect, selectedValue}: {
    options: {
        label: string,
        icon: React.ReactNode,
        value: string
    }[],
    onSelect: (value: any) => void,
    selectedValue: string
}) {
    return (
        <Flex gap={5} wrap={'wrap'}>
            {options.map((option, index) => (
                <Button label={option.label} icon={option.icon} value={option.value} setValue={onSelect}
                        selected={selectedValue === option.value}/>
            ))}
        </Flex>
    )
}

export default RadioButtons;
