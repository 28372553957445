import ContainerBlock from "./ContainerBlock";
import {hourGroupedTransactions} from "../../pages/AnalyticsPage";
import {sumPoints, sumYValues} from "../../utils/analytics";
import SingleLineChart from "./charts/SingleLineChart";

interface MainActivityHoursProps {}

const MainActivityHours = ()=>{

    const data = Object.entries(hourGroupedTransactions.value).map((item: any)=>{
        return {
            x: item[0],
            y: item[1].length
        };
    })

    return(
        <ContainerBlock total={sumYValues(data)} title={'Totale transazioni per fascia oraria'}>
        <SingleLineChart data={data}/>
    </ContainerBlock>);
}
export default MainActivityHours;
