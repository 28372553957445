import CustomersNumberTrend from "../components/Analytics/CustomersNumberTrend";
import AllocatedPointsTrend from "../components/Analytics/AllocatedPointsTrend";
import { useEffect, useState } from "react";
import { supabase } from "../utils/supabase";
import { queryClient, userId } from "../App";
import dayjs from "dayjs";
import { DatePicker, Flex, Segmented, Select } from "antd";
import { computed, signal, useSignal } from "@preact/signals-react";
import {
  getAllDaysBetweenDates,
  groupByHours,
  groupDatesBy,
} from "../utils/analytics";
import SpentPointsTrend from "../components/Analytics/SpentPointsTrend";
import GoalsRank from "../components/Analytics/GoalsRank";
import MainActivityHours from "../components/Analytics/MainActivityHours";
import { SelectOption } from "../types/inputTypes";
import TotalCustomersNumber from "../components/Analytics/TotalCustomersNumber";
import TotalNotificationsSent from "../components/Analytics/TotalNotificationsSend";
import CustomersActiveNotifications from "../components/Analytics/CustomersActiveNotifications";
import NotificationsFrequency from "../components/Analytics/NotificationsFrequency";
import NotificationsReadTime from "../components/Analytics/NotificationsReadTime";
import { useQueries } from "@tanstack/react-query";
import {
  getCustomers,
  getTransactions,
} from "../utils/analyticsFetchFunctions";

require("dayjs/locale/it");
dayjs().locale("it");

export const transactions = signal<any[]>([]);
export const customers = signal<any[]>([]);
export const timeSpan = signal<"year" | "month" | "week">("month");

export const endDate = signal(dayjs());

export const startingDate = computed(() => {
  switch (timeSpan.value) {
    case "year":
      return endDate.value.subtract(1, "year");

    case "month":
      return endDate.value.subtract(1, "month");

    case "week":
      return endDate.value.subtract(1, "week");
  }
});
export const groupedTransactions = computed(() => {
  return groupDatesBy(transactions.value, timeSpan.value);
});

export const groupedPromotions = computed(() => {
  const promotions = transactions.value.filter((item) => item.type === 1);
  return groupDatesBy(promotions, timeSpan.value);
});

export const hourGroupedTransactions = computed(() => {
  return groupByHours(transactions.value);
});

export const groupedCustomers = computed(() => {
  return groupDatesBy(customers.value, timeSpan.value);
});

export const groupedReadNotifications = signal<any[]>([]);

export const allCustomers = signal<any[]>([]);

export const allNotifications = signal<any[]>([]);

export const allNotificationsCount = computed(() => {
  return allNotifications.value.length;
});

export const analyticsLoading = signal<boolean>(true);

const AnalyticsPage = () => {
  const [selectedSalesPointId, setSelectedSalesPointId] = useState("");
  const [salesPointsOptions, setSalesPointsOptions] = useState<SelectOption[]>(
    [],
  );

  const queries = useQueries({
    queries: [
      {
        queryKey: ["analytics", "transactions"],
        queryFn: () => getTransactions(selectedSalesPointId),
        staleTime: 100000,
      },
      {
        queryKey: ["analytics", "customers"],
        queryFn: () => getCustomers(selectedSalesPointId),
        staleTime: 1000000,
      },
    ],
    combine: (results) => {
      return {
        pending: results.some((results) => results.isPending),
        data: results.map((item) => item),
      };
    },
  });

 if (!queries.pending) {
    if (queries.data[0].data) {
      transactions.value = queries.data[0].data;
    }
    if (queries.data[1].data) {
      customers.value = queries.data[1].data;
    }
  }

useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["analytics"] });
    fetchTransactions().then(() => {});
  }, [endDate.value, timeSpan, startingDate.value, selectedSalesPointId]);

  const fetchTransactions = async () => {
    analyticsLoading.value = true;
    try {
      const { data: salesPoints, error: errorSalesPoints } = await supabase
        .from("sales_points")
        .select("name, id")
        .eq("shop_id", userId.value);
      if (errorSalesPoints) {
        throw Error();
      } else {
        const salesPointsOptions = salesPoints.map((point: any) => ({
          value: point.id,
          label: point.name,
        }));
        setSalesPointsOptions([
          { value: "", label: "Tutti i punti vendita" },
          ...salesPointsOptions,
        ]);
      }

      supabase
        .from("users_shops")
        .select("created_at, notifications_allowed")
        .eq("shop_id", userId.value)
        .then((result) => {
          if (result.data) {
            allCustomers.value = result.data;
          }
        });

      supabase
        .from("notifications")
        .select("created_at")
        .eq("shop_id", userId.value)
        .then((result) => {
          if (result.data) {
            allNotifications.value = result.data;
          }
        });

      supabase
        .from("read_notifications")
        .select("created_at")
        .eq("shop_id", userId.value)
        .order("created_at", { ascending: true })
        .gt("created_at", startingDate.value.toISOString())
        .lt("created_at", endDate.value.toISOString())
        .then((result) => {
          if (result.data) {
            groupedReadNotifications.value = groupByHours(result.data);
          }
        });

      analyticsLoading.value = false;
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const disabledDate: any = (current: any) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };


  return (
    <div>
      <Flex
        wrap={"wrap"}
        gap={10}
        style={{ marginBottom: 30 }}
        justify={"space-between"}
      >
        <h1>Analitiche</h1>
        <Flex wrap={"wrap"} gap={8}>
          <Select
            style={{ width: 200, flex: 1 }}
            placeholder={"Punto vendita"}
            options={salesPointsOptions}
            onSelect={setSelectedSalesPointId}
            value={selectedSalesPointId}
          />
          <DatePicker
              allowClear={false}
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
              style={{backgroundColor: 'white'}}
              onChange={(date) => (endDate.value = date)}
              defaultValue={endDate.value}
          />
          <div style={{flex: 1}}>
            <Segmented
              options={[
                { value: "year", label: "Un anno" },
                {
                  value: "month",
                  label: "Un mese",
                },
                { value: "week", label: "Una settimana" },
              ]}
              value={timeSpan.value}
              onChange={(e: any) => {
                timeSpan.value = e;
              }}
            />
          </div>
        </Flex>
      </Flex>
      <div className={"smallAnalyticsGrid"}>
        <TotalCustomersNumber />
        <CustomersActiveNotifications />
        <TotalNotificationsSent />
        <NotificationsFrequency />
      </div>
      <div className={"analyticsGrid"}>
        <CustomersNumberTrend />
        <AllocatedPointsTrend />
        <SpentPointsTrend />
        <GoalsRank />
        <MainActivityHours />
        <NotificationsReadTime />
      </div>
    </div>
  );
};
export default AnalyticsPage;
