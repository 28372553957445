import {cloneElement, useEffect, useRef, useState} from "react";
import {useWindowSize} from "../utils/windowResize";

const PhonePreviewFrame = ({children}: any) => {

    const [width, setWidth] = useState<number>(0);
    const elementRef = useRef<any>(null);
    const [windowWidth, windowHeight] = useWindowSize();
    useEffect(() => {
        if (elementRef?.current?.offsetWidth) {
            setWidth(elementRef.current.offsetWidth);
        }
    }, [windowWidth, windowHeight]);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const onMouseDown = (e: any) => {
        const div = elementRef.current;
        setIsDragging(true);
        setStartX(e.pageX - div.offsetLeft);
        setStartY(e.pageY - div.offsetTop);
        setScrollLeft(div.scrollLeft);
        setScrollTop(div.scrollTop);
    };

    const onMouseLeave = () => {
        setIsDragging(false);
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    const onMouseMove = (e: any) => {
        if (!isDragging) return;
        const div = elementRef.current;
        const x = e.pageX - div.offsetLeft;
        const y = e.pageY - div.offsetTop;
        const walkX = (x - startX) * 1; // Adjust the multiplier for faster/slower scrolling
        const walkY = (y - startY) * 1; // Adjust the multiplier for faster/slower scrolling
        div.scrollLeft = scrollLeft - walkX;
        div.scrollTop = scrollTop - walkY;
    };


    return (
        <div onMouseDown={onMouseDown}
             onMouseLeave={onMouseLeave}
             onMouseUp={onMouseUp}
             onMouseMove={onMouseMove} ref={elementRef} className={'previewPhone'} style={{
            width: 367,
            border: '13px solid #181818',
            borderWidth: width / 25,
            borderRadius: 50,
            overflowY: 'scroll',
            backgroundColor: 'white',
            height: 750,
        }}>
            {cloneElement(children, {width: width})}
        </div>
    );
}
export default PhonePreviewFrame;
