import Summary from "../Summary";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {Button} from "antd";
import {SubscriptionType} from "@virlewood/nif-types";
import {useState} from "react";
import {authDelete} from "../../utils/authCalls";

export interface PaymentFormProps {
    clientSecret: string
    subscriptionInterval: 'month' | 'year',
    subscriptionAmount: number
    extraSalesPointUnitAmount: number
    extraSalesPointsNumber: number
}

if(!process.env.REACT_APP_STRIPE_PUBLIC_KEY){
    throw new Error('no stripe key')
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = (props: SubscriptionType) => {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://app.nextisfree.io",
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        setLoading(false)
    };

    async function cancelSubscription() {
        setLoading(true)
        await authDelete(`/v1/subscriptions/cancel-immediately/${props.subscription_id}`)
        window.location.reload()
    }

    return (

        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <Summary {...props}/>
            <Button loading={loading} disabled={loading} htmlType={'submit'} size={'large'} type={'primary'}
                    style={{borderRadius: 30, marginTop: 20, width: '100%'}}>Acquista</Button>
            <Button onClick={cancelSubscription} loading={loading} disabled={loading} type={'text'}
                    style={{width: '100%', marginTop: 10}}>Annulla abbonamento</Button>
        </form>

    );
}
export default (props: SubscriptionType) => {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: props.client_secret,
    };
    return (
        <div>
            <Elements stripe={stripePromise} options={options}>
                <PaymentForm {...props}/>
            </Elements>
        </div>
    )
};
