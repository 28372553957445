import ContainerBlock from "./ContainerBlock";
import {
    countGoalOccurrences,
    countOccurrences,
    getDistinctGoalNames, groupDatesBy,
} from "../../utils/analytics";
import {groupedPromotions, groupedTransactions, transactions} from "../../pages/AnalyticsPage";
import BarsChart from "./charts/BarsChart";
import {queryClient} from "../../App";

const GoalsRank = () => {

  return (
    <ContainerBlock title={"Promozioni più usate"}>
      <BarsChart
        data={countOccurrences(groupedPromotions.value)}
        keys={getDistinctGoalNames(groupedPromotions.value)}
      />
    </ContainerBlock>
  );
};
export default GoalsRank;
