import {AnimatePresence, motion} from "framer-motion";
import {Button, Flex} from "antd";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {PriceListItem, PriceListItemTypes} from "../../types/PriceListTypes";
import {IconPlus} from "@tabler/icons-react";

interface AddItemsButtonsProps {
}

const AddItemsButtons = ({open, onClick, down = false}: {
    open: boolean,
    onClick: (type: PriceListItemTypes) => void,
    down?: boolean
}) => {
    return (<AnimatePresence initial={false}>
        {open &&
            <motion.div style={{marginTop: 5, marginBottom: 5}} initial={{opacity: 0, height: 25, y: down ? -5 : 5}}
                        animate={{opacity: 1, height: 30, y: 0}}
            >
                <Flex gap={5}>
                    <Button onClick={() => onClick('item')} style={{flex: 1, height: 30}}>{down ?
                        <CaretDownOutlined/> :
                        <CaretUpOutlined size={14}/>}Aggiungi Prodotto</Button>
                    <Button onClick={() => onClick('section')} style={{flex: 1, height: 30}}>{down ?
                        <CaretDownOutlined/> :
                        <CaretUpOutlined size={14}/>}Aggiungi Sezione</Button>
                </Flex>
            </motion.div>
        }
    </AnimatePresence>);
}
export default AddItemsButtons;
