import {supabase} from "./supabase";
import {userId} from "../App";
import {customers, endDate, startingDate} from "../pages/AnalyticsPage";

export async function getTransactions(selectedSalesPointId: string){

    if(!userId.value){
        throw 'no user id'
    }

    let results;
    if (selectedSalesPointId) {
        results = await supabase
            .from("transactions")
            .select("points, created_at, type, goal_name")
            .eq("shop_id", userId.value)
            .eq("sales_point_id", selectedSalesPointId)
            .gt("created_at", startingDate.value.toISOString())
            .lt("created_at", endDate.value.toISOString())
            .order("created_at", { ascending: true });
    } else {
        results = await supabase
            .from("transactions")
            .select("points, created_at, type, goal_name")
            .eq("shop_id", userId.value)
            .gt("created_at", startingDate.value.toISOString())
            .lt("created_at", endDate.value.toISOString())
            .order("created_at", { ascending: true });
    }

    const {data, error} = results;

    if(error){
        throw Error
    }

    return data;

}

export async function getCustomers(selectedSalesPointId: string){

    if(!userId.value){
        throw 'no user id'
    }

    const { data: customersData, error: customersError } = await supabase
        .from("users_shops")
        .select("created_at")
        .lt("created_at", endDate.value.toISOString())
        .order("created_at", { ascending: true })
        .eq("shop_id", userId.value);

    if (customersError) {
        throw customersError;
    }

    return customersData

}
