import SimpleStatistic from "./charts/SimpleStatistic";
import { useEffect, useState } from "react";
import { supabase } from "../../utils/supabase";
import { userId } from "../../App";
import dayjs from "dayjs";
import {
  allNotifications,
  allNotificationsCount,
} from "../../pages/AnalyticsPage";
import { green, red } from "@ant-design/colors";
import { Card } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { computed, useComputed } from "@preact/signals-react";

const NotificationsFrequency = () => {
  const [noData, setNoData] = useState(true);

  const frequency: any = useComputed(() => {
    if (allNotifications.value.length > 0) {
      return calculateFrequency(allNotifications.value[0].created_at);
    }

    return {
      value: 0,
      suffix: "",
      feedback: { color: "", tooltipText: "" },
    };
  });

  function calculateFrequency(oldestNotificationDate: string) {
    const daysDifference = dayjs().diff(oldestNotificationDate, "days");
    if (daysDifference < 31) {
      setNoData(true);
      return {
        value: 0,
        suffix: "",
        feedback: { color: "", tooltipText: "" },
      };
    } else {
      setNoData(false);
    }

    const dayFrequency = allNotificationsCount.value / daysDifference;

    if (dayFrequency >= 1) {
      return {
        value: dayFrequency,
        suffix: "al giorno",
        feedback: {
          color: red[5],
          tooltipText:
            "La frequenza di invio notifiche è troppo alta, i clienti potrebbero decidere di disattivare le notifiche del tuo negozio. (Frequenza ideale tra 2 e 10 al mese)",
        },
      };
    } else {
      const monthFrequency = dayFrequency * 30.44; //average number of days in a month;

      let feedback;

      if (monthFrequency < 10 && monthFrequency > 2) {
        feedback = {
          color: green[6],
          tooltipText:
            "La frequenza di invio notifiche è nella media. Assicurati che questo valore rimanga tra i 2 e i 10 al mese.",
        };
      } else if (monthFrequency >= 10) {
        feedback = {
          color: red[5],
          tooltipText:
            "La frequenza di invio notifiche è troppo alta, i clienti potrebbero decidere di disattivare le notifiche del tuo negozio. (Frequenza ideale tra 2 e 10 al mese)",
        };
      } else {
        feedback = { color: "black", tooltipText: "" };
      }

      return { value: monthFrequency, suffix: "al mese", feedback };
    }
  }

  return (
    <SimpleStatistic
      noData={noData}
      title={"Frequenza invio notifiche"}
      value={frequency.value.value}
      suffix={frequency.value.suffix}
      disableFormatter
      valueStyle={{ color: frequency.value.feedback.color }}
      tooltipText={frequency.value.feedback.tooltipText}
    />
  );
};
export default NotificationsFrequency;
