import { motion } from "framer-motion";
import {COLORS} from "../utils/colors";

interface LoginLayoutProps {}

const LoginLayout = ({ children }: any) => {
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
          position: "relative",
        }}
      >
        <img
          src={"./logo.png"}
          width={100}
          alt={"next is free logo"}
          style={{ position: "absolute", left: 15, top: 15 }}
        />
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          style={{ maxWidth: 350, width: "100%", padding: 15 }}
        >
          {children}
        </motion.div>
      </div>
      <div className='hide-mob' style={{ flex: 1, position: "relative", backgroundColor: COLORS.businessColor }}>
       {/*  <img alt='bg' src={'./blue-bg.png'} width={'100%'} height={'100%'} style={{objectFit: 'cover'}}/>*/}
          <img src={'./logo-white.png'} style={{position: 'absolute', top: '50%', width: '80%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 250}} />
      </div>
    </div>
  );
};
export default LoginLayout;
