import {useEffect, useState} from "react";
import {supabase} from "../../utils/supabase";
import {userId} from "../../App";
import {
    PriceListItem,
    PriceListItemTypes,
    PriceListSource,
    PriceListThemes,
    PriceListTypes
} from "../../types/PriceListTypes";
import PreviewPriceListSection from "./PreviewPriceListSection";
import PreviewPriceListItem from "./PreviewPriceListItem";
import {z} from "zod";
import {COLORS} from "../../utils/colors";
import {Flex} from "antd";

interface PriceListPreviewProps {
    items: PriceListItem[];
    theme: PriceListThemes;
    type: PriceListTypes,
    source: PriceListSource,
    link: string
}

const PriceListPreview = ({items, theme, type, source, link}: PriceListPreviewProps) => {
  /*  const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        const {
            data: {publicUrl}
        } = supabase.storage
            .from("main")
            .getPublicUrl(userId.value + "/logo.png");
        setImageUrl(publicUrl + "?" + Math.random());
    }, []);*/

    const scale = 1.2

    if (source === 'link') {
        return (
            <div style={{width: '100%', height: '100%'}}>
                {z.string().url().safeParse(link).success ?
                    <iframe style={{width: '100%', height: '100%'}} src={link}/> :
                    <Flex justify={'center'} align={'center'} style={{color: COLORS.gray, height: '100%'}}>
                        <p>Inserisci un link valido</p>
                    </Flex>
                }
            </div>
        )
    }

    return (
        <div style={{marginBottom: 100}}>
        {/*    <img style={{width: '100%'}} src={imageUrl}/>*/}
            <div style={{margin: 20 / scale}}>
                {/* <h1 style={{marginBottom: 20 / scale}}>Menu</h1>*/}
                {items.map((item: PriceListItem) => {
                    if(!item.data.available) return;
                    if (item.type === 'item') {
                        return <PreviewPriceListItem key={item.id} item={item} theme={theme}/>
                    }
                    return <PreviewPriceListSection key={item.id} item={item} theme={theme}/>
                })}
            </div>
            {items.length < 1 &&
                <Flex justify={'center'} align={'center'} style={{color: COLORS.gray, height: '100%', position: 'absolute', top:0, left: '50%', transform: 'translateX(-50%)'}}>
                    <p>Nessun elemento inserito</p>
                </Flex>
            }
        </div>
    );
}
export default PriceListPreview;
