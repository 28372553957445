import {PriceListItem, PriceListThemes} from "../../types/PriceListTypes";
import {Flex} from "antd";
import {IconEggs} from "@tabler/icons-react";


interface PriceListItemProps {
}

const PreviewPriceListItem = ({item, theme}: { item: PriceListItem, theme: PriceListThemes }) => {
    return (
        <div style={{marginBottom: 20}}>
            <Flex align={'flex-start'} gap={10} justify={'space-between'}>
                <div>
                    <p className={`item-name ${theme}`}>{item.data.name}</p>
                    <p className={`item-description ${theme}`}>{item.data.description}</p>
                </div>
                {item.data.price &&
                    <p className={`item-price ${theme}`}>{item.data.price}€</p>
                }
            </Flex>
            <Flex gap={2} style={{marginTop: 5}}>
                {item.data.allergens?.map((item) => (
                    <div key={item}>
                        <img style={{width: 16}} src={`./allergens-icons/${item}.svg`}/>
                    </div>
                ))}
            </Flex>
        </div>
    );
}
export default PreviewPriceListItem;
