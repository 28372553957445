import React, {ReactNode, useState} from 'react';
import {
    BookOutlined,
    CreditCardOutlined, MenuOutlined,
    MobileOutlined,
    NotificationOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    StockOutlined, SwapOutlined,
} from '@ant-design/icons';
import {Button, Cascader, Dropdown, Flex, Layout, Menu, MenuProps, Popover, Space, theme} from 'antd';
import {green, red} from "@ant-design/colors";
import {useLocation, useNavigate} from "react-router-dom";
import {completed, publishedComputed, subscription, welcome} from "../App";
import SubscriptionCheckBanner from "../components/SubscriptionCheckBanner";
import TopInfoBanner from "../components/TopInfoBanner";
import {COLORS} from "../utils/colors";
import {MenuItemType} from "antd/lib/menu/interface";
import RoundMoreButton from "../microcomponents/RoundMoreButton";
import {IconDots} from "@tabler/icons-react";

const {Content, Sider} = Layout;

function RedDot() {
    return (
        <span style={{
            position: 'relative',
            width: 7,
            height: 7,
            backgroundColor: "red",
            borderRadius: 50,
            display: 'inline-block',
            top: -6
        }}/>
    )
}

const MainLayout = ({children}: any) => {

    const [collapsed, setCollapsed] = useState(false);

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const navigate = useNavigate()
    const location = useLocation()


    const menuItems: MenuItemType[] = [{
        icon: <StockOutlined/>, key: '/analytics', label: 'Analitiche', onClick: () => {
            navigate('/analytics')
        }
    }, {
        icon: <SwapOutlined/>, key: '/transactions', label: 'Transazioni', onClick: () => {
            navigate('/transactions')
        }
    }, {
        icon: <NotificationOutlined/>,
        key: '/notifications',
        label: 'Notifiche',
        onClick: () => {
            navigate('/notifications')
        },
    }, {
        icon: <MobileOutlined/>,
        key: '/customize',
        label: <div>Personalizza{!completed.value && <RedDot/>}</div>,
        onClick: () => {
            navigate('/customize')
        }

    }, {
        icon: <BookOutlined/>, key: '/resources', label: 'Guida', onClick: () => {
            navigate('/resources')
        }
    }, {
        icon: <CreditCardOutlined/>,
        key: '/billings',
        label: <div>Abbonamento{subscription.value.status === 'no subscription' && <RedDot/>}</div>,
        onClick: () => {
            navigate('/billings')
        }
    },
        {
            icon: <SettingOutlined/>,
            key: '/settings',
            label: 'Impostazioni',

            onClick: () => {
                navigate('/settings')
            }
        }, {
            icon: <div style={{
                width: 10,
                height: 15,
                backgroundColor: publishedComputed.value ? green[6] : red[5],
                borderRadius: 20
            }}/>,
            key: '/status',
            label: publishedComputed.value ? 'Pubblico' : 'Nascosto',
            style: {
                position: 'absolute', bottom: 55, fontWeight: 700, color: publishedComputed.value ? green[6] : red[5],
            },
            onClick: () => {
                navigate('/settings')
            }
        }, {
            icon: <QuestionCircleOutlined/>,
            key: '/help',
            label: 'Aiuto',
            style: {
                position: 'absolute', bottom: 15,
            },
            onClick: () => {
                window.open('https://nextisfree.io/help')
            }
        }]

    const mobileMenuItems = [{
        icon: <StockOutlined/>,
        path: '/analytics',
        label: 'Analitiche',
    },
        {
            icon: <SwapOutlined/>,
            path: '/transactions',
            label: 'Transazioni',
        },
        {
            icon: <NotificationOutlined/>,
            path: '/notifications',
            label: 'Notifiche',
        },
        {
            icon: <MobileOutlined/>,
            path: '/customize',
            label: 'Personalizza',
        },

    ]


    const items: MenuProps['items'] = [
        {
            label: <Flex style={{fontSize: 16}} onClick={() => navigate('/resources')} gap={6}> <BookOutlined/><p>Guida</p></Flex>,
            key: '0',
        },
        {
            label:  <Flex style={{fontSize: 16}} onClick={() => navigate('/billings')} gap={6}><CreditCardOutlined/><p>Abbonamento</p>
            </Flex>,
            key: '1',
        },
        {
            label: <Flex style={{fontSize: 16}} onClick={() => navigate('/settings')} gap={6}> <SettingOutlined/><p>Impostazioni</p></Flex>,
            key: '3',
        },
    ];


    return (
        <Layout style={{minHeight: '100vh'}}>
            <div className={'mobile-top-nav-w'}>
                <nav style={{
                    position: 'fixed',
                    backgroundColor: 'white',
                    top: 0,
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    borderBottom: `1px solid ${COLORS.lightGray}`,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    zIndex: 999,
                    height: 45,
                    padding: '8px 15px',
                    fontSize: 20
                }}>
                    <Flex align={'center'}>
                        <img height={25} src={'/favicon.ico'}/>
                        <b style={{marginLeft: 3, fontSize: 15, marginTop: 3}}>Business</b>
                    </Flex>
                    <Dropdown menu={{items}} placement="bottomRight">
                        <Button style={{width: 35, height: 35, padding: 0}}><IconDots size={20}/></Button>
                    </Dropdown>
                </nav>
            </div>
            <Layout>
                <Sider
                    className={'side-menu'}
                    collapsedWidth={60}
                    theme={'dark'}
                    breakpoint={'lg'}
                    onCollapse={(state) => {
                        setCollapsed(state)
                    }}
                    width={200}
                    style={{
                        minHeight: 10,
                        height: '100vh',
                        position: 'relative',
                    }}
                >
                    <div style={{
                        position: 'fixed',
                        borderRight: `1px solid ${COLORS.lightGray}`,
                        height: '100vh',
                        minHeight: 500,
                        width: collapsed ? 60 : 200,
                        backgroundColor: 'white'
                    }}>
                        <div style={{padding: '40px 20px'}}>
                            {
                                !collapsed ? <img height={60} src={'/logo.png'}/> :
                                    <img width={'100%'} src={'/favicon.ico'}/>
                            }

                        </div>
                        <Menu
                            mode='inline'
                            selectedKeys={[location.pathname]}
                            style={{
                                padding: 0,
                                backgroundColor: 'white',
                            }}
                            items={menuItems}
                        />
                    </div>
                </Sider>
                <Layout
                    style={{backgroundColor: 'white'}}
                >
                    {!completed.value &&
                        <TopInfoBanner onClick={() => navigate('/customize')}
                                       text={", completa il profilo per essere visibile nell'app"}
                                       boldText={'Il tuo negozio è nascosto'} buttonText={'Completa profilo'}/>
                    }
                    <SubscriptionCheckBanner/>
                    <Content
                        className={'main-layout'}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>

            <div className={'mobile-bottom-nav-w'}>
                <div style={{borderTopColor: COLORS.lightGray}} className={'mobile-bottom-nav'}>
                    {mobileMenuItems.map((item) => (
                        <BottomNavBtn {...item}/>
                    ))}
                </div>
            </div>

        </Layout>
    );
};
export default MainLayout;

function BottomNavBtn({icon, path, label}: any) {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate(path)} style={{
            backgroundColor: pathname == path ? COLORS.primary : 'white',
            borderRadius: 20,
            height: 38,
            padding: '0 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: pathname == path ? 'black' : COLORS.gray,
        }}>
            {icon}
            {pathname == path &&
                <p style={{fontSize: 12, marginLeft: 4}}>{label}</p>
            }
        </div>
    )
}
