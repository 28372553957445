import {ReactNode} from "react";
import {grey} from "@ant-design/colors";
import CountUp from "react-countup";
import {COLORS} from "../../utils/colors";

interface ContainerBlockProps {
    title: string;
    children: ReactNode;
    total?: number;
}

const ContainerBlock = ({title, children, total}: ContainerBlockProps) => {

    const formatter: any = (value: number) => <CountUp duration={1} preserveValue={true} decimal={','} end={value}/>;

    return (
        <div
            style={{
                width: "100%",
                aspectRatio: 1.8,
                minHeight: 250,
                border: `1px solid ${COLORS.lightGray}`,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 20,
                minWidth: 30,
                backgroundColor: 'white'
            }}
        >
            <h3 style={{width: "100%"}}>{title}</h3>
            {total && <p style={{width: "100%", color: grey[2]}}>Totale: {formatter(total)}</p>}

            <div style={{width: "100%", height: "calc(100% - 40px)"}}>
                {children}
            </div>
        </div>
    );
};
export default ContainerBlock;
