import {supabase} from "./supabase";
import {logged, onBoarded, userId, verified} from "../App";

export async function signOut() {
    const { error } = await supabase.auth.signOut();
    userId.value = "";
    logged.value = false;
    verified.value = false;
    onBoarded.value = false;
}
