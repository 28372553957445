import {Button, Form, message, Modal} from "antd";
import SubscriptionInput from "../SubscriptionInput";
import {subscription} from "../../App";
import axios from "axios";
import {authPut} from "../../utils/authCalls";

interface ChangePlanModalProps {
}

const ChangePlanModal = ({open, setChangePlanModal}: {
    open: boolean,
    setChangePlanModal: (open: boolean) => void
}) => {

    const {interval} = subscription.value
    const [form] = Form.useForm();
    const subscriptionInputValue = Form.useWatch('subscription', form)

    if (!interval) {
        return <div/>
    }

    const intervalToLookup = {
        year: 'yearly',
        month: 'monthly',
    }

    async function handleSubmit(values: any){
        console.log({...values.subscription})
        await authPut('/v1/subscriptions/', {...values.subscription})
    }

    return (
        <Modal open={open} title={'Seleziona un nuovo piano di abbonamento'} okText={'Passa al nuovo piano'}
               footer={null}>
            {/*@ts-ignore*/}
            <Form onFinish={handleSubmit} form={form} layout={'vertical'}
                  initialValues={{subscription: {promoCode: '', subscriptionType: intervalToLookup[interval]}}}>
                <Form.Item label={'Codice promo'} name={'subscription'}>
                    {/*@ts-ignore*/}
                    <SubscriptionInput/>
                </Form.Item>
                <Button style={{width: '100%', marginTop: 15}} size={'large'} type='primary'
                        disabled={subscriptionInputValue?.subscriptionType === intervalToLookup[interval] && !subscriptionInputValue.promoCode || !subscriptionInputValue?.subscriptionType}
                        htmlType={'submit'}>Cambia piano di abbonamento</Button>
            </Form>
        </Modal>
    );
}
export default ChangePlanModal;
