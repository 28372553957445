import SimpleStatistic from "./charts/SimpleStatistic";
import {allCustomers} from "../../pages/AnalyticsPage";

interface TotalCustomersNumberProps {}

const TotalCustomersNumber = () => {

  return <SimpleStatistic title={'Totale clienti'} value={allCustomers.value.length}/>;
};
export default TotalCustomersNumber;
