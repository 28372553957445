import {Button, Flex} from "antd";
import {
    ArrowRightOutlined,
    CloudDownloadOutlined,
    CreditCardFilled, CreditCardOutlined,
    EditOutlined,
    FolderAddOutlined, QrcodeOutlined
} from "@ant-design/icons";
import {blue, gray, grey} from "@ant-design/colors"
import React, {useEffect, useState} from "react";
import MyDocumentLink from "../components/ResourcesPDF";
import StepCircleIndicator from "../components/StepCircleIndicator";
import {Link} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {supabase} from "../utils/supabase";
import {completed, subscription, userId} from "../App";
import Wrapper from "../components/Wrapper";

interface ResourcesPageProps {
}

const ResourcesPage = () => {

    const [qrCode, setQrCode] = useState(false);

    useEffect(()=>{
        async function getProfileQrCode(){
            const {data, error} = await supabase.from('shops').select('qr_code').eq('id', userId.value)
            if(error){
                throw error
            }
            console.log(data)
            if(data[0].qr_code){
                setQrCode(true)
            }
        }

        getProfileQrCode()
    },[])


    return (
        <div className={"container"} style={{display: "flex", justifyContent: "space-between", flexDirection: 'column', gap: 20}}>
            <h1>Guida</h1>
            <Wrapper>
                <StepCircleIndicator step={1} done={completed.value}/>
                <Flex wrap={'wrap'} gap={5} align={"center"} style={{marginBottom: 30}}>
                    <div style={{flex: 1, textAlign: 'center'}}>
                        <EditOutlined style={{fontSize: 60, textAlign: 'center', color: 'gray'}}/>
                    </div>
                    <div style={{flex: 1.5, minWidth: 200}}>
                        <h1>Personalizza il tuo profilo</h1>
                        <p style={{textWrap: "pretty", marginTop: 10, marginBottom: 10}}>
                            Personalizza il modo in cui i clienti vedono il tuo negozio nell'app e stabilisci i
                            traguardi.
                        </p>
                        <Link to={'/customize'}>Personalizza <ArrowRightOutlined/></Link>
                    </div>
                </Flex>
            </Wrapper>
            <Wrapper>
                <StepCircleIndicator step={2} done={!!subscription.value.default_payment_method}/>
                <Flex wrap={'wrap'} gap={5} align={"center"} style={{marginBottom: 30}}>
                    <div style={{flex: 1, textAlign: 'center'}}>
                        <CreditCardOutlined style={{fontSize: 60, textAlign: 'center', color: 'gray'}}/>
                    </div>
                    <div style={{flex: 1.5, minWidth: 200}}>
                        <h1>Aggiungi un metodo di pagamento</h1>
                        <p style={{textWrap: "pretty", marginTop: 10, marginBottom: 10}}>
                            Aggiungi un metodo di pagamento valido per il rinnovo automatico dell'abbonamento.
                        </p>
                        <Link to='/billings'>Aggiungi <ArrowRightOutlined/></Link>
                    </div>
                </Flex>
            </Wrapper>
            <Wrapper>
                <StepCircleIndicator step={3} done={qrCode}/>
                <Flex gap={5} wrap={'wrap'} style={{marginBottom: 30}}>
                    <Flex align={"center"} justify={"center"} style={{flex: 1, minWidth: 200}}>
                        <img
                            style={{width: "100%", maxWidth: 100, borderRadius: 10}}
                            alt={"logo App business"}
                            src={"./logo-app-business.png"}
                        />
                    </Flex>
                    <div style={{flex: 1.5, minWidth: 200}}>
                        <h2>Installa l'app Business</h2>
                        <p style={{marginTop: 10}}>
                            Cerca <b>Next is Free Business</b> nell'App Store e installala.
                            L'app business ti permette di assegnare punti ai clienti e
                            convalidare le promozioni.
                        </p>
                    </div>
                </Flex>
            </Wrapper>
            <Wrapper>
                <StepCircleIndicator step={4} done={qrCode}/>
                <Flex wrap={'wrap'} gap={5} style={{marginBottom: 30}}>
                    <Flex align={"center"} justify={"center"} style={{flex: 1, minWidth: 200}}>
                        <img
                            style={{width: "100%", maxWidth: 150}}
                            alt={"logo App business"}
                            src={"./business-mockup.png"}
                        />
                    </Flex>
                    <Flex justify={"center"} vertical style={{flex: 1.5, minWidth: 200}}>
                        <h2>Accedi e inizia a usare Next is Free</h2>
                        <p style={{marginTop: 10}}>
                            Usa le stesse credenziali di questa dashboard per accedere al tuo
                            negozio.
                        </p>
                    </Flex>
                </Flex>
            </Wrapper>
            <Wrapper>
                <StepCircleIndicator step={5} done={qrCode}/>
                <Flex wrap={'wrap'} gap={5} style={{marginBottom: 30}}>
                    <Flex align={"center"} justify={"center"} style={{flex: 1, minWidth: 200}}>
                        <QrcodeOutlined style={{fontSize: 60, textAlign: 'center', color: 'gray'}}/>
                    </Flex>
                    <Flex justify={"center"} vertical style={{flex: 1.5, minWidth: 200}}>
                        <h2>Associa un qr code al tuo negozio</h2>
                        <p style={{marginTop: 10}}>
                            Al momento della tua iscrizione ti abbiamo inviato il Welcome Kit contenente i
                            qr codes. Per associare i qr codes al tuo negozio dovrai semplicemente inquadrarne uno con
                            l'app business. Una volta associato, quel qr code identificherà il tuo negozio.
                        </p>
                    </Flex>
                </Flex>
            </Wrapper>
            <div style={{ textAlign: 'center', margin: 60, fontSize: 16, }}>
                Per una guida più approfondita sul funzionamento di Next is Free consulta l'
                <a target='_blank' href={'https://nextisfree.io/profilo/personalizza'}>Help Center</a>
            </div>
        </div>
    );
};
export default ResourcesPage;
