import ContainerBlock from "./ContainerBlock";
import {groupedReadNotifications, hourGroupedTransactions} from "../../pages/AnalyticsPage";
import {sumPoints, sumYValues} from "../../utils/analytics";
import SingleLineChart from "./charts/SingleLineChart";
import {useEffect} from "react";

interface MainActivityHoursProps {}

const NotificationsReadTime = ()=>{


    const data = Object.entries(groupedReadNotifications?.value).map((item: any)=>{
            return {
                x: item[0],
                y: item[1].length
            };
        })


    return(
        <ContainerBlock total={sumYValues(data)} title={'Totale lettura notifiche per fascia oraria'}>
          <SingleLineChart data={data}/>
        </ContainerBlock>);
}
export default NotificationsReadTime;
