import {Button, Form, Input, Select} from 'antd'
import {SelectOption} from "../../types/inputTypes";
import ProductInput from "../ProductsInput";

interface Step1Props {
    onSubmit: () => void,
    setFormValues: (values: any) => void
    formValues: any
    loading: boolean
    businessCategories: SelectOption[]
}

const Step1 = ({onSubmit, setFormValues, formValues, loading, businessCategories}: Step1Props) => {
    const [form] = Form.useForm();


    async function onFinish(values: any) {
        console.log(values)
        setFormValues({...values})
        onSubmit()
    }


    return (
        <div data-matomo-form={true} data-matomo-name="onBoarding">
            <Form initialValues={formValues} form={form} onFinish={onFinish} layout={'vertical'}>
                <Form.Item rules={[
                    {required: true, message: "Inserisci il nome del negozio"},
                ]}
                           required={false}
                           name={"business_name"} label={'Nome attività'}>
                    <Input placeholder={'es. Pizzeria della Volta'} size={'large'}/>
                </Form.Item>
                <Form.Item rules={[{required: true, message: "Seleziona una categoria"}]}
                           required={false}
                           name={"business_category"} label={'Categoria'}>

                    <Select
                        loading={loading}
                        placeholder={"Seleziona una categoria"}
                        size={"large"}
                        options={businessCategories}
                    />
                </Form.Item>
                {/* <ProductInput/>*/}
                {/*    <Form.Item name={'products'} label={'Prodotti o servizi'}>
                    <Select placeholder={'Es. Pizza, taglio di capelli'} size={'large'} mode={'tags'}/>
                </Form.Item>*/}
                <Button type={'primary'} size={'large'}
                        style={{float: 'right', width: '100%', marginTop: 18, borderRadius: 30, height: 43}}
                        htmlType={'submit'}>Continua</Button>
            </Form>
        </div>
    );
}
export default Step1;
