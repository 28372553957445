import ContainerBlock from "./ContainerBlock";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import {blue, orange} from "@ant-design/colors";
import SingleLineChartTimeFormat from "./charts/SingleLineChartTimeFormat";
import {groupedCustomers, groupedTransactions, hourGroupedTransactions} from "../../pages/AnalyticsPage";
import {sumYValues} from "../../utils/analytics";

interface CustomersNumberTrendProps {}


const CustomersNumberTrend = () => {

  const data = groupedCustomers.value.map((item: any)=>{
    return({x: item.key, y: item?.data?.length || 0})
  })

  return (
    <ContainerBlock total={sumYValues(data)} title={"Andamento numero clienti"}>
      <SingleLineChartTimeFormat data={data}/>
    </ContainerBlock>
  );
};
export default CustomersNumberTrend;
