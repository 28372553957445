import {Badge, Collapse, Flex, Form} from "antd";
import {allergens, allergensObject} from "../../types/PriceListTypes";
import {COLORS} from "../../utils/colors";
import Wrapper from "../Wrapper";

interface SelectAllergensProps {
}

const SelectAllergens = ({ value, onChange }: any) => {


    const handleChange = (tag: string, checked: boolean) => {
        const data = value || []
        const nextSelectedTags = !checked
            ? [...data, tag]
            : data.filter((t: string) => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        onChange(nextSelectedTags)
    };

    return (
        <Wrapper style={{padding: 0}}>
            <Collapse ghost items={[{
                label: <span>Aggiungi allergeni {value && value?.length > 0 &&
                    <Badge color={'blue'}/>}</span>,
                children: <Flex wrap={'wrap'} gap={5}>
                    {allergens.map((tag) => {
                        const checked = value?.includes(tag) || false
                        return (
                            <Flex
                                gap={3}
                                key={tag}
                                style={{
                                    cursor: 'pointer',
                                    padding: 4,
                                    backgroundColor: checked ? 'black' : COLORS.lightGray,
                                    borderRadius: 20,
                                    color: checked ? 'white' : 'black',
                                }}
                                onClick={() => handleChange(tag, checked)}
                            >
                                <img width={15}
                                     src={'./allergens-icons/' + allergensObject[tag].icon}/>{allergensObject[tag].label}
                            </Flex>
                        )
                    })}
                </Flex>
            }]}/>
        </Wrapper>
    );
}
export default SelectAllergens;
